import Icon from "@ant-design/icons";


const Svg = () => (
    <svg width="323" height="340" viewBox="0 0 323 340" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M161.5 340C72.306 340 0 267.683 0 178.475C0 89.2674 72.306 16.9502 161.5 16.9502C250.694 16.9502 323 89.2674 323 178.475C323 267.683 250.694 340 161.5 340Z"
            fill="#DFF1FA"/>
        <path opacity="0.2"
              d="M43.4467 262.588C43.6146 262.102 44.1183 261.616 44.4541 261.13C47.8121 257.891 51.6738 255.462 55.5356 253.032C61.9158 248.983 68.464 245.096 75.0121 241.209C78.0343 239.428 81.2244 237.646 84.7504 237.484C91.8022 237.16 97.175 243.315 103.052 247.364C105.906 249.307 109.264 250.927 112.454 252.385C114.133 253.194 115.812 253.842 117.491 254.652L99.8615 244.772L200.602 188.572L275.318 230.196L263.397 236.836L303.921 263.279L301.343 267.916L239.891 302.43L192.711 276.193L174.577 286.396L151.071 273.277C153.422 275.869 155.437 278.784 156.612 282.185C157.284 283.805 157.619 285.586 156.948 287.206C155.94 289.959 152.415 290.769 149.56 291.579C144.187 292.875 139.15 294.656 134.113 296.924C131.091 298.381 128.069 300.001 124.711 300.811C121.353 301.62 117.659 301.62 114.805 299.677C109.936 296.438 109.6 289.473 105.906 284.939C99.5257 277.326 86.0936 279.918 78.0343 273.763C77.1948 273.115 76.1874 272.306 75.18 271.982C74.1726 271.82 73.1652 271.982 72.3257 272.306C69.6393 273.115 66.9528 273.763 64.2664 274.573C62.7553 275.059 61.0763 275.383 59.5652 275.059C58.3899 274.735 57.3825 274.087 56.3751 273.439C52.8492 271.01 49.1553 268.581 45.6294 266.151C44.4541 265.341 42.943 264.046 43.4467 262.588Z"
              fill="#00549D"/>
        <path
            d="M225.788 31.5408L170.381 0.606445C169.037 -0.0413949 168.03 -0.203375 167.19 0.282506L123.704 24.7385C122.865 25.2244 122.361 26.1961 122.361 27.4918L122.193 235.773C122.193 238.526 124.208 241.927 126.726 243.385L182.134 274.319C183.645 275.129 184.82 275.129 185.66 274.319C185.492 274.481 185.492 274.481 185.324 274.481L228.81 250.025C229.65 249.539 230.153 248.567 230.153 247.272L230.321 38.991C230.321 36.3996 228.306 32.9985 225.788 31.5408Z"
            fill="#779CBF"/>
        <path
            d="M124.04 177.456L167.526 153C168.533 152.353 170.045 152.514 171.556 153.324L128.069 177.78C126.39 176.97 125.047 176.808 124.04 177.456Z"
            fill="#B3B3B3"/>
        <path
            d="M181.126 207.426C184.316 209.207 186.835 213.418 186.835 216.981L186.667 270.59C186.667 274.153 184.149 275.449 180.958 273.667L127.902 244.028C124.712 242.247 122.193 238.036 122.193 234.473L122.361 180.864C122.361 177.463 124.879 176.005 128.07 177.787L181.126 207.426Z"
            fill="#051117"/>
        <path
            d="M179.28 209.373L126.223 179.734C124.712 178.925 123.201 178.763 122.193 179.41L115.477 183.298C114.47 183.783 113.798 185.079 113.798 186.861L113.63 240.469C113.63 244.033 116.149 248.244 119.339 250.025L172.396 279.664C173.907 280.474 175.418 280.636 176.425 279.988L183.141 276.101C184.149 275.453 184.82 274.319 184.82 272.538L184.988 218.929C184.988 215.366 182.47 211.155 179.28 209.373Z"
            fill="white"/>
        <path
            d="M172.564 213.264C175.754 215.046 178.272 219.257 178.272 222.82L178.105 276.429C178.105 279.992 175.586 281.288 172.396 279.506L119.339 249.867C116.149 248.086 113.631 243.875 113.631 240.312L113.798 186.703C113.798 183.302 116.317 181.844 119.507 183.626L172.564 213.264Z"
            fill="#EDEDED"/>
        <path
            d="M144.693 206.628V208.571L174.243 225.091V222.824C174.243 222.662 174.243 222.5 174.243 222.176C173.907 220.232 172.396 217.641 170.717 216.669L148.722 204.36C146.875 203.55 144.693 204.684 144.693 206.628Z"
            fill="#779CBF"/>
        <path
            d="M182.302 55.9918C184.82 57.4495 186.835 60.8506 186.835 63.6039L186.667 208.72C186.667 211.473 184.652 212.607 182.134 211.15L126.726 180.215C124.208 178.758 122.193 175.356 122.193 172.603L122.361 27.4868C122.361 24.7335 124.376 23.5998 126.894 25.0574L182.302 55.9918Z"
            fill="#051117"/>
        <path
            d="M129.077 176.163C128.069 175.677 127.062 173.896 127.062 172.762L127.23 30.8848L179.783 60.1995C180.79 60.6854 181.798 62.6289 181.798 63.6007L181.63 205.478L129.077 176.163Z"
            fill="white"/>
        <path
            d="M134.281 25.8688L183.14 53.0781C184.987 54.2118 188.177 54.2118 190.024 53.0781L221.253 35.7484C223.1 34.6147 223.1 32.995 221.253 31.8613L172.394 4.65205C170.547 3.51833 167.357 3.51833 165.51 4.65205L134.281 21.9818C132.434 23.1155 132.434 24.7351 134.281 25.8688Z"
            fill="#CAE5FC"/>
        <mask id="mask0_4256_25405" maskUnits="userSpaceOnUse" x="127" y="30" width="55"
              height="176">
            <path
                d="M129.077 176.162C128.069 175.676 127.062 173.895 127.062 172.761L127.23 30.8838L179.783 60.1985C180.79 60.6844 181.798 62.6279 181.798 63.5997L181.63 205.477L129.077 176.162Z"
                fill="white"/>
        </mask>
        <g mask="url(#mask0_4256_25405)">
            <path
                d="M130.253 169.521V40.6011C130.253 38.4956 131.764 35.9042 133.779 34.7705L165.008 17.4407C166.855 16.307 168.534 17.2788 168.534 19.3843V148.305C168.534 150.41 167.023 153.001 165.008 154.135L133.779 171.465C131.764 172.437 130.253 171.627 130.253 169.521Z"
                fill="#CAE5FC"/>
        </g>
        <mask id="mask1_4256_25405" maskUnits="userSpaceOnUse" x="127" y="30" width="55"
              height="176">
            <path
                d="M129.077 176.162C128.069 175.676 127.062 173.895 127.062 172.761L127.23 30.8838L179.783 60.1985C180.79 60.6844 181.798 62.6279 181.798 63.5997L181.63 205.477L129.077 176.162Z"
                fill="white"/>
        </mask>
        <g mask="url(#mask1_4256_25405)">
            <path
                d="M175.082 132.76L224.949 160.617C227.132 161.751 227.132 163.694 224.949 164.99L189.186 184.911C187.003 186.045 183.645 186.045 181.463 184.911L131.596 157.216C129.413 156.082 129.413 154.138 131.596 152.843L167.359 132.922C169.374 131.626 172.9 131.626 175.082 132.76ZM168.87 133.57L151.744 143.125L155.774 145.393L175.25 134.541L173.739 133.732C172.228 132.922 170.045 132.922 168.87 133.57ZM170.045 153.329L175.082 156.082L194.559 145.231L189.522 142.477L170.045 153.329ZM188.011 141.668L182.974 138.914L163.497 149.766L168.534 152.519L188.011 141.668ZM176.593 157.054L181.63 159.807L201.107 148.956L196.07 146.202L176.593 157.054ZM182.974 160.617L188.011 163.37L207.487 152.519L202.45 149.766L182.974 160.617ZM189.522 164.18L194.559 166.933L214.035 156.082L208.998 153.329L189.522 164.18ZM195.902 167.743L200.939 170.496L220.416 159.645L215.379 156.892L195.902 167.743ZM194.391 168.553L174.914 179.404L179.951 182.158L199.428 171.306L194.391 168.553ZM192.88 167.743L187.843 164.99L168.366 175.841L173.403 178.594L192.88 167.743ZM186.5 164.18L181.463 161.427L161.986 172.278L167.023 175.031L186.5 164.18ZM179.951 160.617L174.914 157.864L155.438 168.715L160.475 171.468L179.951 160.617ZM173.571 157.054L168.534 154.3L149.058 165.152L154.095 167.905L173.571 157.054ZM167.023 153.329L161.986 150.575L142.509 161.427L147.546 164.18L167.023 153.329ZM160.643 149.766L155.606 147.012L136.129 157.864L141.166 160.617L160.643 149.766ZM162.154 148.956L181.63 138.104L176.593 135.351L157.117 146.202L162.154 148.956ZM223.438 161.427L221.927 160.617L202.45 171.468L206.48 173.736L223.606 164.18C224.781 163.37 224.781 162.075 223.438 161.427ZM187.675 183.939L204.801 174.383L200.771 172.116L181.295 182.967L182.806 183.777C184.317 184.749 186.5 184.749 187.675 183.939ZM133.107 156.244L134.618 157.054L154.095 146.202L150.065 143.935L132.939 153.491C131.764 154.3 131.764 155.434 133.107 156.244Z"
                fill="#779CBF"/>
        </g>
        <mask id="mask2_4256_25405" maskUnits="userSpaceOnUse" x="127" y="30" width="55"
              height="176">
            <path
                d="M129.077 176.162C128.069 175.676 127.062 173.895 127.062 172.761L127.23 30.8838L179.783 60.1985C180.79 60.6844 181.798 62.6279 181.798 63.5997L181.63 205.477L129.077 176.162Z"
                fill="white"/>
        </mask>
        <g mask="url(#mask2_4256_25405)">
            <path
                d="M175.082 99.7175L224.949 127.413C227.132 128.546 227.132 130.49 224.949 131.786L189.186 151.707C187.003 152.84 183.645 152.84 181.463 151.707L131.596 124.011C129.413 122.878 129.413 120.934 131.596 119.639L167.359 99.7175C169.374 98.5838 172.9 98.5838 175.082 99.7175ZM168.87 100.527L151.744 110.083L155.774 112.35L175.25 101.499L173.739 100.689C172.228 99.8794 170.045 99.8794 168.87 100.527ZM170.045 120.286L175.082 123.04L194.559 112.188L189.522 109.435L170.045 120.286ZM188.011 108.625L182.974 105.872L163.497 116.723L168.534 119.477L188.011 108.625ZM176.593 123.85L181.63 126.603L201.107 115.752L196.07 112.998L176.593 123.85ZM182.974 127.575L188.011 130.328L207.487 119.477L202.45 116.723L182.974 127.575ZM189.522 131.138L194.559 133.891L214.035 123.04L208.998 120.286L189.522 131.138ZM195.902 134.701L200.939 137.454L220.416 126.603L215.379 123.85L195.902 134.701ZM194.391 135.511L174.914 146.362L179.951 149.115L199.428 138.264L194.391 135.511ZM192.88 134.701L187.843 131.948L168.366 142.799L173.403 145.552L192.88 134.701ZM186.5 131.138L181.463 128.384L161.986 139.236L167.023 141.989L186.5 131.138ZM179.951 127.575L174.914 124.821L155.438 135.673L160.475 138.426L179.951 127.575ZM173.571 123.85L168.534 121.096L149.058 131.948L154.095 134.701L173.571 123.85ZM167.023 120.286L161.986 117.533L142.509 128.384L147.546 131.138L167.023 120.286ZM160.643 116.723L155.606 113.97L136.129 124.821L141.166 127.575L160.643 116.723ZM162.154 115.913L181.63 105.062L176.593 102.309L157.117 113.16L162.154 115.913ZM223.438 128.384L221.927 127.575L202.45 138.426L206.48 140.693L223.606 131.138C224.781 130.328 224.781 129.032 223.438 128.384ZM187.675 150.897L204.801 141.341L200.771 139.074L181.295 149.925L182.806 150.735C184.317 151.545 186.5 151.545 187.675 150.897ZM133.107 123.04L134.618 123.85L154.095 112.998L150.065 110.731L132.939 120.286C131.764 121.096 131.764 122.392 133.107 123.04Z"
                fill="#779CBF"/>
        </g>
        <mask id="mask3_4256_25405" maskUnits="userSpaceOnUse" x="127" y="30" width="55"
              height="176">
            <path
                d="M129.077 176.162C128.069 175.676 127.062 173.895 127.062 172.761L127.23 30.8838L179.783 60.1985C180.79 60.6844 181.798 62.6279 181.798 63.5997L181.63 205.477L129.077 176.162Z"
                fill="white"/>
        </mask>
        <g mask="url(#mask3_4256_25405)">
            <path opacity="0.5"
                  d="M175.082 68.1358L224.949 95.9929C227.132 97.1266 227.132 99.0701 224.949 100.366L189.186 120.287C187.003 121.421 183.645 121.421 181.463 120.287L131.596 92.5917C129.413 91.458 129.413 89.5145 131.596 88.2188L167.359 68.2977C169.374 67.002 172.9 67.002 175.082 68.1358Z"
                  fill="#5EA6E9"/>
        </g>
        <mask id="mask4_4256_25405" maskUnits="userSpaceOnUse" x="127" y="30" width="55"
              height="176">
            <path
                d="M129.077 176.162C128.069 175.676 127.062 173.895 127.062 172.761L127.23 30.8838L179.783 60.1985C180.79 60.6844 181.798 62.6279 181.798 63.5997L181.63 205.477L129.077 176.162Z"
                fill="white"/>
        </mask>
        <g mask="url(#mask4_4256_25405)">
            <path opacity="0.5"
                  d="M175.082 38.3331L224.949 66.0283C227.132 67.162 227.132 69.1055 224.949 70.4012L189.186 90.3223C187.003 91.456 183.645 91.456 181.463 90.3223L131.596 62.6271C129.413 61.4934 129.413 59.5499 131.596 58.2542L167.359 38.3331C169.374 37.0374 172.9 37.0374 175.082 38.3331Z"
                  fill="#5EA6E9"/>
        </g>
        <path
            d="M144.692 208.561L174.242 225.081V222.814C174.242 222.652 174.242 222.49 174.242 222.166L147.378 207.104L144.692 208.561Z"
            fill="white"/>
        <path
            d="M179.28 56.9708L126.223 27.3321C124.712 26.5223 123.201 26.3603 122.193 27.0081L115.477 30.8952C114.47 31.3811 113.798 32.6768 113.798 34.4583L113.63 169.857C113.63 173.42 116.149 177.631 119.339 179.413L172.396 209.051C173.907 209.861 175.418 210.023 176.425 209.375L183.141 205.488C184.149 204.84 184.82 203.707 184.82 201.925L184.988 66.5264C184.988 62.9633 182.47 58.7523 179.28 56.9708Z"
            fill="white"/>
        <path
            d="M172.565 60.8552C175.755 62.6368 178.273 66.8477 178.273 70.4109L178.106 205.81C178.106 209.373 175.587 210.668 172.397 208.887L119.34 179.248C116.15 177.466 113.631 173.255 113.631 169.692L113.799 34.2937C113.799 30.8926 116.318 29.4349 119.508 31.2165L172.565 60.8552Z"
            fill="#EDEDED"/>
        <path
            d="M145.366 182.97V186.371C145.366 188.314 146.373 190.096 148.22 191.068L170.719 203.539C171.726 204.186 172.902 203.701 173.405 202.729C173.573 202.567 173.573 202.243 173.573 201.919V198.518L148.052 184.265L145.366 182.97Z"
            fill="white"/>
        <path
            d="M148.052 184.433V185.566C148.052 187.51 149.06 189.291 150.907 190.263L173.405 202.734C173.573 202.572 173.573 202.248 173.573 201.924V198.523L148.052 184.433Z"
            fill="#779CBF"/>
        <path
            d="M144.69 206.621V208.565H147.545L147.377 207.107C147.377 205.812 147.713 204.84 148.721 204.354C146.874 203.545 144.69 204.678 144.69 206.621Z"
            fill="white"/>
        <path d="M240.901 196.586V298.621L194.393 272.708V170.673L240.901 196.586Z" fill="white"/>
        <path d="M194.392 170.674L240.9 196.587L303.863 161.604L257.355 135.69L194.392 170.674Z" fill="#8ABDF1"/>
        <path d="M303.863 161.604V263.477L240.9 298.623V196.588L303.863 161.604Z" fill="#EDEDED"/>
        <path d="M303.863 161.604V189.138L240.9 224.283V196.588L303.863 161.604Z" fill="#CAE5FC"/>
        <path
            d="M266.926 196.587C266.926 197.559 267.094 198.369 267.262 199.017C268.437 201.932 271.963 202.742 275.993 200.312C281.03 197.397 285.228 191.243 285.228 186.222C285.228 182.497 282.709 180.553 279.351 181.201C278.344 181.363 277.168 181.849 276.161 182.497C270.956 185.412 266.926 191.729 266.926 196.587Z"
            fill="#8ABDF1"/>
        <path
            d="M252.149 250.194C252.149 262.503 262.391 266.714 275.152 259.588C287.912 252.462 298.154 236.913 298.154 224.604C298.154 212.295 287.912 208.084 275.152 215.211C262.391 222.337 252.149 237.885 252.149 250.194Z"
            fill="#CAE5FC"/>
        <path
            d="M253.829 251.814C253.829 264.123 264.071 268.334 276.832 261.208C289.592 254.082 299.834 238.533 299.834 226.224C299.834 213.916 289.592 209.705 276.832 216.831C264.071 223.957 253.829 239.667 253.829 251.814Z"
            fill="white"/>
        <path
            d="M259.538 248.734C259.538 250.516 259.874 251.973 260.378 253.269C262.561 258.938 269.109 260.071 276.832 255.86C286.402 250.516 294.126 238.854 294.126 229.623C294.126 222.497 289.425 218.933 282.877 220.229C281.03 220.553 278.847 221.363 276.832 222.497C267.262 227.679 259.538 239.502 259.538 248.734Z"
            fill="#182939"/>
        <path
            d="M259.538 248.737C259.538 250.519 259.874 251.977 260.378 253.272C263.232 255.216 267.765 255.054 272.802 252.301C281.365 247.442 288.417 236.914 288.417 228.654C288.417 224.119 286.402 221.204 283.044 220.232C281.197 220.556 279.015 221.366 277 222.5C267.261 227.683 259.538 239.506 259.538 248.737Z"
            fill="#051117"/>
        <path opacity="0.75"
              d="M273.976 252.784C274.312 252.784 274.648 252.784 274.984 252.46C283.379 247.764 290.431 237.236 290.431 229.3C290.431 228.328 289.591 227.519 288.584 227.519C287.576 227.519 286.737 228.328 286.737 229.3C286.737 235.779 280.357 245.334 273.305 249.221C272.465 249.707 272.129 250.841 272.633 251.651C272.633 252.46 273.305 252.784 273.976 252.784Z"
              fill="white"/>
        <path opacity="0.2"
              d="M266.421 244.366C267.261 244.366 267.932 243.88 268.268 243.232C269.611 239.831 271.794 236.43 274.648 233.515C275.32 232.705 275.32 231.571 274.48 230.923C273.641 230.276 272.466 230.276 271.794 231.085C268.604 234.325 266.085 238.212 264.574 242.099C264.238 243.07 264.742 244.042 265.75 244.528C266.085 244.366 266.253 244.366 266.421 244.366Z"
              fill="white"/>
        <path
            d="M276.997 187.351C277.669 187.027 278.173 186.865 278.676 186.703C279.516 186.541 280.188 186.703 280.691 187.027L280.859 187.189L281.027 187.351L282.706 188.647L281.867 189.942C281.699 192.534 279.516 195.611 276.997 197.069C276.83 197.231 276.662 197.231 276.494 197.231L275.318 198.85L272.968 197.231C272.632 196.907 272.464 196.583 272.296 196.259C272.128 195.935 272.128 195.449 272.128 194.963C271.96 192.21 274.143 188.809 276.997 187.351Z"
            fill="#182939"/>
        <path
            d="M274.145 196.421C274.145 196.907 274.145 197.392 274.312 197.716C274.984 199.336 276.831 199.66 279.014 198.364C281.7 196.907 283.883 193.505 283.883 190.914C283.883 188.809 282.54 187.837 280.693 188.161C280.189 188.323 279.517 188.485 279.014 188.809C276.327 190.428 274.145 193.667 274.145 196.421Z"
            fill="white"/>
        <path d="M263.566 199.012L243.922 210.025V219.581L263.566 208.729V199.012Z" fill="#8ABDF1"/>
        <path d="M263.566 187.355L243.922 198.369V207.924L263.566 197.073V187.355Z" fill="white"/>
        <path d="M302.183 165.648L288.247 173.423V195.125L302.183 187.351V165.648Z" fill="white"/>
        <path d="M289.591 175.04L295.804 171.64V174.716L289.591 178.117V175.04Z" fill="#182939"/>
        <path d="M289.591 179.419L295.804 176.019V179.257L289.591 182.658V179.419Z" fill="#8ABDF1"/>
        <path d="M289.591 184.436L295.804 181.035V184.112L289.591 187.513V184.436Z" fill="#182939"/>
        <path d="M289.591 188.811L295.804 185.41V188.649L289.591 192.05V188.811Z" fill="#8ABDF1"/>
        <path d="M298.994 170.027L297.147 170.999V172.942L298.994 171.971V170.027Z" fill="#CAE5FC"/>
        <path d="M299.497 169.704V171.647L301.177 170.676V168.732L299.497 169.704Z" fill="#8ABDF1"/>
        <path d="M298.994 172.783L297.147 173.755V175.86L298.994 174.888V172.783Z" fill="#182939"/>
        <path d="M299.497 172.458V174.563L301.177 173.591V171.486L299.497 172.458Z" fill="#8ABDF1"/>
        <path d="M298.992 175.855L297.145 176.827V178.77L298.992 177.799V175.855Z" fill="#8ABDF1"/>
        <path d="M299.497 175.532V177.475L301.176 176.504V174.561L299.497 175.532Z" fill="#CAE5FC"/>
        <path d="M298.992 178.611L297.145 179.583V181.688L298.992 180.717V178.611Z" fill="#182939"/>
        <path d="M299.497 178.285V180.39L301.176 179.419V177.313L299.497 178.285Z" fill="#182939"/>
        <path d="M298.993 181.684L297.146 182.655V184.598L298.993 183.627V181.684Z" fill="#CAE5FC"/>
        <path d="M299.497 181.36V183.303L301.176 182.332V180.389L299.497 181.36Z" fill="#CAE5FC"/>
        <path d="M298.993 184.439L297.146 185.411V187.516L298.993 186.545V184.439Z" fill="#182939"/>
        <path d="M299.497 184.113V186.218L301.176 185.247V183.142L299.497 184.113Z" fill="#182939"/>
        <path d="M259.537 207.923L247.952 214.239V215.373L259.537 209.057V207.923Z" fill="white"/>
        <path
            d="M54.1925 146.697C52.6814 146.535 52.1777 143.781 52.3456 139.732L58.0543 140.542C59.3975 143.619 57.2147 145.239 54.1925 146.697Z"
            fill="#D4EEFF"/>
        <path
            d="M41.6003 141.186L43.7831 144.911L45.2942 147.665C47.4769 147.827 49.8275 147.989 52.0102 147.017L55.3682 147.341H56.0398L60.4053 147.503L59.3979 141.51L54.025 139.243C54.025 139.243 53.5213 137.137 52.6818 135.842C51.8423 134.546 51.1707 133.574 51.1707 131.145C51.1707 128.392 51.3386 127.258 50.8349 127.258C47.309 127.258 48.8201 135.518 48.8201 135.518C46.3016 135.356 42.6078 135.032 42.272 136.328L41.6003 141.186Z"
            fill="#FFB294"/>
        <path opacity="0.4"
              d="M43.4472 136.978L43.951 144.914L45.4621 147.667C47.6448 147.829 49.9954 147.991 52.1781 147.019L55.5361 147.343H55.3682C52.8497 146.209 51.1707 143.78 51.1707 141.027C51.1707 135.196 45.6299 137.625 43.4472 136.978Z"
              fill="#FFB294"/>
        <path
            d="M42.104 136.171C43.2793 136.981 45.2941 137.629 47.4768 137.79C50.6669 137.79 50.1633 140.22 48.988 140.544C49.9954 140.868 50.8349 141.192 50.667 142.163C50.4991 143.135 49.4916 143.459 48.4842 143.459C49.6595 143.783 49.6595 146.05 46.8052 145.889C46.8052 145.889 48.3163 146.536 47.141 147.508C46.6373 147.994 45.462 147.994 43.6151 147.346L42.6077 147.022C40.0892 144.269 42.104 136.171 42.104 136.171Z"
            fill="#FFB294"/>
        <path
            d="M54.1936 139.242L80.722 136.165C82.9047 128.715 83.9121 122.236 88.9492 110.413C93.6504 100.048 100.199 97.4562 108.258 100.695L101.878 125.799C98.0159 133.735 93.8183 143.939 87.2702 148.636C83.2405 151.065 56.2084 148.15 53.522 147.016C54.5294 146.368 57.0479 142.481 54.1936 139.242Z"
            fill="white"/>
        <path
            d="M136.297 273.348C137.472 276.911 137.64 277.882 137.137 280.636C136.633 282.093 135.961 282.741 135.458 284.523C134.786 286.952 134.786 290.839 131.092 293.269C126.727 296.67 118.164 296.832 115.477 293.269C115.477 291.487 116.653 287.762 118.835 284.523L123.201 276.749L122.865 269.461L135.29 266.869L136.297 273.348Z"
            fill="black"/>
        <path
            d="M112.623 255.854C113.798 257.312 113.63 260.875 113.295 264.762C112.119 268.649 108.258 268.325 104.9 268.811C98.5193 269.621 95.1613 274.318 86.5984 274.156C81.5613 273.994 78.2033 273.022 77.1959 269.783C77.028 268.325 78.0354 266.868 79.7144 265.41C91.4675 260.551 91.4675 260.551 91.8033 260.389C92.1391 260.227 97.344 256.664 97.5119 256.178C97.6798 255.854 99.6946 247.271 99.6946 247.271L112.287 247.432L112.623 255.854Z"
            fill="black"/>
        <path
            d="M116.989 164.023L107.923 211.639C109.266 226.216 114.471 240.468 113.128 254.559C111.952 260.389 99.5275 259.58 97.1769 255.207C98.0164 244.355 91.3003 228.159 88.7818 210.344C88.2781 192.69 88.446 174.389 92.1399 160.46L116.989 164.023Z"
            fill="#11304D"/>
        <path
            d="M90.2913 169.853C90.795 171.635 91.2987 173.254 91.8024 174.712C91.8024 174.874 91.9703 175.036 91.9703 175.198C93.9851 179.895 96.3357 182.324 100.03 184.268C100.869 184.753 101.876 185.077 102.884 185.563C108.928 187.993 115.644 188.317 122.025 187.831C122.193 187.831 122.192 187.831 122.36 187.831C123.2 187.831 124.039 187.669 125.047 187.507C127.062 187.345 129.076 187.021 130.923 186.697C131.091 186.049 131.259 185.401 131.427 184.592C132.77 178.761 132.099 174.874 131.259 169.529C131.091 168.881 131.091 168.396 130.923 167.748L131.091 167.424C117.659 172.121 99.358 168.396 90.9629 156.734C90.795 162.727 90.6271 167.748 90.2913 169.853Z"
            fill="#214B71"/>
        <path
            d="M91.9712 175.04C95.3292 188.969 104.732 189.455 104.9 192.856C105.235 203.383 107.25 216.664 109.097 228.487C114.806 245.655 120.179 262.013 121.354 272.864C123.537 275.455 129.917 276.427 133.779 274.97C134.282 274.808 134.786 274.484 135.29 274.16C136.297 273.35 136.969 272.378 136.969 271.083C136.801 257.316 134.282 243.387 130.253 229.297L131.26 184.596L131.428 178.279L117.996 175.688L92.8107 170.991C92.6428 172.935 91.4675 172.773 91.9712 175.04Z"
            fill="#214B71"/>
        <path opacity="0.5"
              d="M116.989 173.58C117.324 174.228 117.996 175.038 117.996 175.848C118.668 179.735 115.142 187.995 119.003 191.882C127.902 200.79 124.88 214.07 123.537 229.133C132.435 241.118 135.122 256.666 133.947 275.129C134.45 274.967 134.954 274.644 135.458 274.32C136.465 273.51 137.137 272.538 137.137 271.242C136.969 257.476 134.45 243.547 130.421 229.457L131.428 184.756C132.771 178.925 132.1 175.038 131.26 169.693L116.989 173.58Z"
              fill="#214B71"/>
        <path
            d="M90.124 121.265C90.124 121.265 90.9635 141.996 90.9635 156.572C91.6351 168.557 105.403 174.226 117.156 173.578C123.033 173.254 128.405 171.149 131.092 167.262L133.275 158.03L133.778 155.763L135.457 148.96L135.793 146.693L135.961 144.911L136.129 144.101L138.647 122.237C137.64 121.103 136.633 119.969 135.457 118.836C135.122 118.512 134.786 118.188 134.45 117.864C133.61 117.054 132.771 116.244 131.931 115.435C131.596 115.111 131.26 114.787 130.924 114.463C126.726 110.576 122.025 107.013 116.988 103.773H98.3512C93.6499 107.66 91.2993 113.815 90.124 121.265Z"
            fill="#214B71"/>
        <path
            d="M94.824 122.077C98.8536 127.097 106.745 130.499 116.651 133.09C117.994 122.887 122.863 114.789 130.587 114.303C126.389 110.416 121.688 106.852 116.651 103.613H101.876C97.3425 108.958 94.9919 116.57 94.824 122.077Z"
            fill="white"/>
        <path
            d="M120.681 155.603C121.688 156.089 122.864 156.413 124.039 156.575H124.207C124.543 156.575 124.711 156.575 125.046 156.575H125.214C125.55 156.575 125.886 156.575 126.39 156.575C126.725 156.575 127.061 156.575 127.565 156.575C127.901 156.575 128.236 156.575 128.404 156.575C129.076 156.575 129.748 156.413 130.251 156.413C131.091 156.251 131.762 156.251 132.602 156.089C132.77 156.089 132.77 156.089 132.938 156.089C133.274 156.089 133.441 155.927 133.777 155.927C134.953 150.906 135.624 150.582 136.296 145.561C136.296 144.914 136.464 144.428 136.464 143.618C136.967 139.731 137.303 133.414 138.311 122.239C137.303 121.105 136.296 119.972 135.12 118.838C134.785 118.514 134.449 118.19 134.113 117.866C129.915 118.514 124.711 120.62 123.032 127.098C119.674 139.893 119.506 148.801 120.681 155.603Z"
            fill="white"/>
        <path opacity="0.5"
              d="M116.988 173.579C122.865 173.255 128.238 171.15 130.924 167.263L133.107 158.031L133.61 155.764C130.756 156.412 127.062 156.897 124.04 156.573C123.033 163.214 120.682 169.044 116.988 173.579Z"
              fill="#214B71"/>
        <path opacity="0.2"
              d="M124.041 156.407H124.209C124.545 156.407 124.713 156.407 125.048 156.407H125.216C125.552 156.407 125.888 156.407 126.392 156.407C126.727 156.407 127.063 156.407 127.567 156.407C127.903 156.407 128.239 156.407 128.406 156.407C129.078 156.407 129.75 156.245 130.253 156.245C131.093 156.083 131.764 156.083 132.604 155.921C132.772 155.921 132.772 155.921 132.94 155.921C133.276 155.921 133.444 155.759 133.779 155.759C134.955 150.738 135.626 150.415 136.298 145.394L134.955 142.317L124.713 137.134C125.048 144.098 125.048 150.577 124.041 156.407Z"
              fill="#214B71"/>
        <path
            d="M121.857 177.466L133.61 160.46C138.311 162.242 141.334 165.319 142.845 169.854C137.136 174.55 131.763 178.599 126.39 183.296C122.529 181.029 121.521 178.923 121.857 177.466Z"
            fill="#FFB294"/>
        <path
            d="M121.857 174.874C122.025 176.008 127.23 184.43 129.245 186.049L126.895 188.803C126.895 188.803 119.675 178.923 119.003 176.818L121.857 174.874Z"
            fill="#214B71"/>
        <path
            d="M108.089 119.645C115.645 121.75 121.857 112.357 121.018 107.498C120.346 102.477 119.842 99.7236 119.842 99.7236L105.067 103.287C105.067 103.287 104.899 104.582 104.899 106.364V106.526C104.563 113.49 106.074 119.159 108.089 119.645Z"
            fill="#FFB294"/>
        <path opacity="0.5"
              d="M108.088 119.644C114.972 120.454 116.315 104.582 116.315 104.582L104.898 106.202V106.364C104.562 113.49 106.073 119.158 108.088 119.644Z"
              fill="#FFB294"/>
        <path
            d="M118.666 102.15C118.666 102.15 116.987 108.305 114.301 110.896C111.447 113.487 106.913 114.945 105.234 114.945C103.555 115.107 102.212 114.945 101.037 113.649C99.8613 112.354 99.1897 100.692 99.1897 100.692C99.1897 96.1576 99.3576 94.8619 99.8613 92.2706C100.701 88.5455 103.387 80.2855 114.637 81.9051C120.009 82.553 123.367 86.44 124.039 90.9749C124.543 94.7 121.521 101.178 121.521 101.178L118.666 102.15Z"
            fill="#FFB294"/>
        <path
            d="M121.52 101.83L116.147 104.583L115.811 101.506C115.811 100.048 114.804 99.5622 113.293 99.7241C110.774 99.8861 103.219 98.5904 101.372 90.6543C99.8605 91.3022 99.5247 92.9218 99.3568 93.2457C95.663 85.9575 105.905 81.5846 112.621 81.7465C121.016 82.0705 124.374 87.4151 124.374 92.112C124.542 96.4849 121.52 101.83 121.52 101.83Z"
            fill="black"/>
        <path
            d="M122.529 176.333L138.312 153.658L124.544 136.977L138.312 122.076C138.312 122.076 154.095 147.828 154.095 153.658C154.095 158.841 150.233 166.129 126.727 182.973C124.544 180.706 124.208 179.086 122.529 176.333Z"
            fill="white"/>
        <path
            d="M120.346 97.7791C121.018 97.4553 122.026 97.4553 122.529 98.103C122.865 98.4269 122.865 98.9127 122.865 99.3985C122.865 99.8843 122.529 100.208 122.361 100.694C122.026 101.342 121.69 101.989 121.186 102.637C120.85 103.123 120.346 103.771 119.843 103.933C119.171 104.095 118.331 104.58 117.995 103.933C117.659 103.447 117.659 101.828 117.827 101.342C118.163 99.8843 119.171 98.2649 120.346 97.7791Z"
            fill="#FFB294"/>
        <path opacity="0.5"
              d="M103.222 111.389C110.273 111.389 117.493 98.2703 117.493 98.2703L99.024 96.9746C98.6882 106.53 98.3524 111.389 103.222 111.389Z"
              fill="#FFB294"/>
        <path
            d="M113.63 100.7C117.995 100.538 121.353 99.0802 123.872 97.1366C123.872 97.1366 127.733 85.4755 117.324 81.7504C100.533 75.5959 97.847 91.63 97.847 91.63C99.0223 96.4888 105.571 99.0801 113.63 100.7Z"
            fill="#214B71"/>
        <path
            d="M104.228 97.1354C107.25 98.755 110.776 100.051 114.134 100.537C113.966 100.537 113.966 100.698 113.798 100.698C108.089 103.776 100.87 107.177 93.482 102.966C85.4227 98.4311 91.9709 94.868 97.6795 91.6288C97.8474 91.6288 97.8474 91.4668 98.0153 91.4668C99.0227 93.4103 101.038 95.3538 104.228 97.1354Z"
            fill="#11304D"/>
        <path
            d="M42.44 25.3872C42.44 25.873 42.6079 26.197 42.9437 26.5209L52.1783 34.6189C52.5141 34.9428 52.8499 35.4287 52.8499 36.0765C52.8499 37.0483 52.5141 38.182 51.8425 39.3157L44.2869 51.1389C43.9511 51.6247 43.6153 52.1106 43.1116 52.4345L37.7388 47.5757C37.5709 47.4138 37.403 47.4137 37.0672 47.4137C37.403 47.4137 30.3511 44.0126 30.3511 44.0126C29.3437 43.5267 27.8326 44.8224 27.3289 46.442C26.3215 50.0051 25.8178 53.5683 26.1536 56.8075C26.6573 64.4196 30.0153 70.5741 35.0524 73.0035L39.2499 75.1089C40.2573 76.0807 40.761 77.7003 40.9289 79.6438L49.4919 222.331C49.6598 225.246 48.6523 228.809 46.8054 232.048C42.9437 238.851 40.761 246.301 41.2647 252.941C41.2647 254.237 41.6005 255.533 41.7684 256.828C42.7758 261.039 45.7981 264.764 49.8277 266.708L56.8795 270.109C61.077 272.214 66.9536 272.214 72.6622 268.327C73.6696 267.518 74.5091 266.06 74.3412 264.926C74.3412 264.602 74.1733 264.278 74.0054 263.954L65.4425 253.589C65.1067 253.265 64.9388 252.779 64.9388 252.293C64.9388 251.16 65.2746 249.864 66.1141 248.73L74.3412 237.555C74.677 237.231 79.2104 242.9 79.2104 242.9C79.3783 243.062 79.3783 243.062 79.5462 243.223L86.598 246.625C86.4301 246.625 86.4301 246.463 86.2622 246.301C87.1017 247.434 89.1165 245.977 89.7881 244.033C90.9635 239.984 91.2992 236.097 91.1313 232.372C90.6276 225.084 87.7733 219.091 82.9042 216.986C83.0721 216.986 77.6992 214.395 77.6992 214.395C76.8597 213.261 76.3561 211.803 76.3561 210.184L67.7931 67.6588C67.6252 64.7435 68.6326 61.3423 70.3116 58.2651C72.8301 53.4063 74.845 48.0616 75.6845 42.8789C76.0203 40.6114 76.1881 38.506 76.0202 36.5624C75.6844 29.7601 72.4943 25.2252 67.961 23.2817C68.2968 23.4437 61.2449 20.0425 61.2449 20.0425C56.5437 17.7751 50.3314 18.4229 44.4548 22.3099C42.9437 22.6339 42.2721 24.0915 42.44 25.3872Z"
            fill="#3B5E7D"/>
        <path
            d="M33.0364 60.3671C32.8685 57.1278 33.2043 53.5647 34.2117 50.0016C34.7154 48.0581 36.5623 46.9244 37.5697 47.7342L47.308 56.318C48.3154 57.1278 49.9944 56.48 51.1697 54.6984L58.7253 42.8754C59.3969 41.7417 59.7327 40.6079 59.7327 39.6362C59.7327 38.9883 59.5648 38.5025 59.0611 38.1785L49.8265 30.0805C49.4907 29.7566 49.4907 29.4327 49.3228 28.9468C49.3228 27.8131 49.9944 26.1935 51.0018 25.5456C62.4191 18.0955 74.6759 22.6304 75.5154 36.5589C75.6833 38.5025 75.5154 40.6079 75.1796 42.8754C74.3401 48.2201 72.4932 53.4028 69.8068 58.2616C68.1278 61.5008 67.1204 64.74 67.2883 67.6553L75.8512 210.18C76.0191 213.419 77.5302 215.849 79.8808 216.173C86.2611 217.306 90.2907 223.947 90.7944 232.369C90.9623 235.932 90.6265 239.981 89.4512 244.03C88.9475 246.135 86.9327 247.431 85.9253 246.297L77.866 236.418C77.0265 235.446 75.3475 235.932 74.0043 237.551L65.7772 248.727C64.9377 249.86 64.4339 251.318 64.6018 252.29C64.6018 252.776 64.7697 253.262 65.1055 253.585L73.6685 263.951C73.8364 264.275 74.0043 264.599 74.0043 264.923C74.0043 266.056 73.3327 267.676 72.3253 268.324C61.5796 275.774 50.8339 269.296 48.4833 260.388C48.1475 259.092 47.9796 257.796 47.8117 256.501C47.4759 250.022 49.6586 242.41 53.3524 235.608C55.1993 232.369 56.2068 228.806 56.0389 225.89L47.4759 83.2034C47.308 80.1262 45.9648 77.8587 43.7821 77.3729C37.9056 75.9152 33.5401 68.9509 33.0364 60.3671Z"
            fill="#ADC1D9"/>
        <path
            d="M44.9587 58.7417C46.134 59.7134 47.4773 60.0374 48.9884 59.8754C50.8353 59.5515 52.8501 58.2558 54.0254 56.3123L61.581 44.4892C61.7489 44.1653 61.9168 44.0033 62.0847 43.6794C62.9242 42.2218 63.26 40.6021 63.0921 39.3065C62.9242 37.8488 62.4205 36.3912 61.4131 35.5814L53.018 28.1312C53.018 27.9693 53.1859 27.9693 53.1859 27.9693C57.8871 24.892 62.7563 24.2442 66.4501 26.0258C69.976 27.6454 71.9908 31.3705 72.3266 36.5532C72.4945 38.3347 72.3266 40.2783 71.9908 42.2218C71.3192 46.9186 69.6402 51.7774 67.1217 56.3123L66.9538 56.6362C65.4427 59.3895 63.9316 63.6005 64.0995 67.8115L72.6624 210.336C72.9982 215.357 75.5167 218.758 79.5464 219.406C83.9118 220.216 87.1019 225.399 87.6057 232.525C87.7736 235.44 87.4377 238.517 86.7661 241.595L80.8896 234.306C80.0501 233.173 78.539 232.525 77.0278 232.525C75.013 232.525 73.1662 233.659 71.655 235.602L63.4278 246.777C63.092 247.263 62.9241 247.587 62.5883 248.073C61.7488 249.531 61.4131 251.15 61.581 252.608C61.581 253.742 62.0846 254.875 62.7562 255.847L70.8155 265.565C70.6476 265.727 70.6476 265.889 70.6476 265.889C65.9464 269.128 61.9167 268.804 59.3982 267.832C55.8723 266.537 53.018 263.459 52.1785 259.734C51.8427 258.762 51.6748 257.629 51.6748 256.333C51.339 250.988 53.018 244.51 56.376 238.194C56.5439 237.87 57.2155 236.736 57.2155 236.736C59.2303 233.011 60.2378 229.124 59.902 225.723L51.339 83.0357C51.0032 78.3389 48.6525 75.0997 45.1266 74.2899C40.5933 73.1561 37.0674 67.4875 36.5637 60.1993C36.3958 57.446 36.7316 54.6927 37.4032 51.9394L44.9587 58.7417Z"
            fill="white"/>
        <path
            d="M42.2718 136.811C42.9435 136.973 43.6152 136.973 44.2869 136.973C47.3096 136.973 46.9737 139.402 45.7982 139.726C46.8058 140.05 47.6454 140.374 47.4775 141.345C47.3096 142.317 46.3021 142.641 45.2945 142.641C46.47 142.965 46.47 145.232 43.6152 145.071C43.6152 145.071 45.1266 145.718 43.9511 146.69C43.6152 147.014 43.1115 146.528 42.2718 146.366V136.811Z"
            fill="#FFB294"/>
        <path d="M235.19 152.369L206.814 136.335V171.804L235.357 187.838L235.19 152.369Z" fill="#CAE5FC"/>
        <path d="M281.866 126.295L253.323 110.423L206.814 136.336L235.19 152.371L281.866 126.295Z" fill="white"/>
        <path d="M235.189 152.364L235.357 187.833L282.033 161.919L281.865 126.288L235.189 152.364Z" fill="#779CBF"/>
        <path
            d="M247.447 177.147L279.348 159.331C280.02 159.007 280.355 158.36 280.355 157.712V130.178C280.355 129.693 279.684 129.207 279.18 129.531L246.775 147.67C246.272 147.994 245.936 148.48 245.936 148.966V176.337C245.936 177.147 246.775 177.471 247.447 177.147Z"
            fill="white"/>
        <path d="M248.119 149.45L278.341 132.606V157.71L248.119 174.554V149.45Z" fill="#192936"/>
        <path d="M210.34 165.802V170.175L209.668 169.851V165.479L210.34 165.802Z" fill="#192936"/>
        <path d="M211.683 166.613V170.985L211.011 170.499V166.127L211.683 166.613Z" fill="#192936"/>
        <path d="M213.026 167.259V171.632L212.355 171.308V166.936L213.026 167.259Z" fill="#192936"/>
        <path d="M214.37 168.07V172.442L213.698 172.118V167.584L214.37 168.07Z" fill="#192936"/>
        <path d="M215.714 168.72V173.255L215.042 172.769V168.396L215.714 168.72Z" fill="#192936"/>
        <path d="M217.057 169.525V173.897L216.385 173.573V169.201L217.057 169.525Z" fill="#192936"/>
        <path d="M218.399 170.336V174.709L217.56 174.223V169.851L218.399 170.336Z" fill="#192936"/>
        <path d="M219.575 170.982V175.354L218.903 175.03V170.658L219.575 170.982Z" fill="#192936"/>
        <path d="M210.341 142.484V147.018L209.669 146.532V142.16L210.341 142.484Z" fill="#192936"/>
        <path d="M211.682 143.298V147.67L211.01 147.346V142.974L211.682 143.298Z" fill="#192936"/>
        <path d="M213.026 143.941V148.475L212.355 147.989V143.617L213.026 143.941Z" fill="#192936"/>
        <path d="M214.369 144.756V149.128L213.697 148.804V144.432L214.369 144.756Z" fill="#192936"/>
        <path d="M215.714 145.563V149.935L215.042 149.611V145.077L215.714 145.563Z" fill="#192936"/>
        <path d="M217.056 146.208V150.742L216.384 150.256V145.884L217.056 146.208Z" fill="#192936"/>
        <path d="M218.398 147.019V151.391L217.559 151.068V146.695L218.398 147.019Z" fill="#192936"/>
        <path d="M219.574 147.664V152.198L218.903 151.712V147.34L219.574 147.664Z" fill="#192936"/>
        <path d="M248.959 171.64L277.502 155.768V156.253L248.959 172.126V171.64Z" fill="#57646B"/>
        <path d="M248.959 169.693L277.502 153.821V154.307L248.959 170.179V169.693Z" fill="#57646B"/>
        <path d="M248.959 152.853L277.502 136.98V137.466L248.959 153.338V152.853Z" fill="#57646B"/>
        <path d="M248.959 151.071L277.502 135.199V135.685L248.959 151.557V151.071Z" fill="#57646B"/>
        <path opacity="0.2" d="M259.537 144.269L253.156 170.83L261.887 165.81L268.268 139.41L259.537 144.269Z"
              fill="white"/>
        <path opacity="0.2" d="M270.618 138.114L264.07 164.676L268.267 162.408L274.647 135.847L270.618 138.114Z"
              fill="white"/>
        <path
            d="M240.229 153.987C240.565 153.825 240.901 153.663 241.236 153.663C241.74 153.501 242.076 153.663 242.412 153.825C242.412 153.825 242.579 153.825 242.579 153.987L243.587 154.635L243.083 155.282C242.915 156.74 241.74 158.522 240.229 159.493C240.061 159.493 240.061 159.655 239.893 159.655L239.221 160.627L237.878 159.655C237.71 159.493 237.542 159.331 237.375 159.007C237.207 158.845 237.207 158.522 237.207 158.198C237.375 156.74 238.718 154.797 240.229 153.987Z"
            fill="#182939"/>
        <path
            d="M238.717 159.17C238.717 159.494 238.717 159.656 238.885 159.98C239.221 160.951 240.396 161.113 241.571 160.465C243.083 159.656 244.426 157.713 244.426 156.093C244.426 154.96 243.586 154.312 242.579 154.474C242.243 154.474 241.907 154.636 241.571 154.798C239.892 155.607 238.717 157.551 238.717 159.17Z"
            fill="white"/>
        <path d="M244.256 162.086L237.372 165.811V182.817L244.256 179.092V162.086Z" fill="white"/>
        <path d="M238.377 167.27L243.247 164.518V166.947L238.377 169.699V167.27Z" fill="#182939"/>
        <path d="M238.377 170.669L243.247 167.916V170.507L238.377 173.098V170.669Z" fill="#8ABDF1"/>
        <path d="M238.377 174.559L243.247 171.806V174.235L238.377 176.988V174.559Z" fill="#182939"/>
        <path d="M238.377 177.957L243.247 175.204V177.795L238.377 180.548V177.957Z" fill="#8ABDF1"/>
        <path
            d="M247.951 128.067L235.023 135.356C230.993 137.623 224.613 137.623 220.751 135.356C216.722 133.088 216.722 129.525 220.751 127.419L233.68 120.131C237.709 117.864 244.09 117.864 247.951 120.131C251.981 122.399 251.981 125.962 247.951 128.067Z"
            fill="#1182BF"/>
        <path d="M250.974 115.754V124.176L217.729 122.88V131.302L250.974 115.754Z" fill="#1182BF"/>
        <path
            d="M247.951 119.806L235.023 127.094C230.993 129.361 224.613 129.361 220.751 127.094C216.722 124.826 216.722 121.263 220.751 119.158L233.68 111.87C237.709 109.602 244.09 109.602 247.951 111.87C251.981 113.975 251.981 117.538 247.951 119.806Z"
            fill="#779CBF"/>
        <path d="M243.082 122.557L250.974 123.529V90.0029L243.082 91.6225V122.557Z" fill="#1182BF"/>
        <path d="M243.081 122.556L228.642 114.458V85.9531L243.081 93.8892V122.556Z" fill="#32BFED"/>
        <path d="M243.081 113.81L228.642 105.874V105.55L243.081 113.486V113.81Z" fill="#779CBF"/>
        <path
            d="M227.635 126.778C229.818 126.778 231.833 126.292 233.512 125.482C236.702 123.7 236.702 120.785 233.512 118.842C231.833 117.87 229.818 117.546 227.635 117.546C225.452 117.546 223.438 118.032 221.759 118.842C218.569 120.623 218.569 123.538 221.759 125.482C223.438 126.454 225.452 126.778 227.635 126.778Z"
            fill="black"/>
        <path opacity="0.75"
              d="M234.351 106.853C238.381 109.768 239.724 116.732 235.862 119.971C235.862 119.971 236.366 121.915 233.512 123.534C231.833 124.506 229.818 124.83 227.635 124.83C225.453 124.83 223.438 124.344 221.759 123.534C218.904 121.915 219.408 119.971 219.408 119.971C215.546 116.732 217.058 109.768 220.919 106.853H234.351Z"
              fill="#779CBF"/>
        <path opacity="0.5"
              d="M222.264 123.049C223.775 123.859 225.79 124.183 227.637 124.183C229.651 124.183 231.498 123.859 233.009 123.049C234.856 122.078 235.528 120.62 235.192 119.324C235.024 118.838 234.688 118.352 234.185 117.867C233.849 117.543 233.513 117.381 233.177 117.219C231.666 116.409 229.651 116.085 227.805 116.085C225.79 116.085 223.943 116.409 222.432 117.219C221.928 117.543 221.592 117.705 221.256 118.029C220.753 118.514 220.417 119 220.249 119.486C219.745 120.62 220.417 122.078 222.264 123.049Z"
              fill="#603813"/>
        <path opacity="0.3"
              d="M221.591 120.296C221.591 120.296 222.263 120.944 223.438 121.268C220.08 118.515 220.92 114.142 222.263 111.712C223.102 110.255 223.774 109.607 224.781 109.121L222.263 107.987C222.263 107.987 215.715 114.952 221.591 120.296Z"
              fill="white"/>
        <path opacity="0.15"
              d="M221.591 120.296C221.591 120.296 222.263 120.944 223.438 121.268C220.08 118.515 220.92 114.142 222.263 111.712C223.102 110.255 223.774 109.607 224.781 109.121L222.263 107.987C222.263 107.987 215.715 114.952 221.591 120.296Z"
              fill="white"/>
        <path
            d="M227.636 110.902C229.315 110.902 231.162 110.578 232.505 109.768C235.191 108.311 235.191 105.881 232.505 104.424C231.162 103.614 229.483 103.29 227.636 103.29C225.957 103.29 224.11 103.614 222.766 104.424C220.08 105.881 220.08 108.311 222.766 109.768C224.11 110.578 225.957 110.902 227.636 110.902Z"
            fill="#192936"/>
        <path
            d="M227.635 109.606C229.146 109.606 230.489 109.282 231.665 108.635C233.847 107.339 233.847 105.396 231.665 104.101C230.489 103.453 229.146 103.129 227.635 103.129C226.124 103.129 224.781 103.453 223.605 104.101C221.423 105.396 221.423 107.339 223.605 108.635C224.781 109.282 226.124 109.606 227.635 109.606Z"
            fill="#666666"/>
        <path
            d="M236.534 121.92L237.878 121.108H237.71C237.542 121.108 236.367 121.92 236.534 121.92C236.367 121.92 236.367 121.92 236.534 121.92Z"
            fill="black"/>
        <path d="M235.694 112.361L237.037 111.714L233.679 109.771L232.335 110.418L235.694 112.361Z" fill="#333333"/>
        <path
            d="M237.709 122.888L239.052 122.078C240.563 121.268 241.403 118.029 238.212 112.685C237.877 112.199 237.541 111.713 237.037 111.551L235.694 112.361C236.197 112.523 236.533 113.008 236.869 113.494C239.892 119.001 239.22 122.078 237.709 122.888Z"
            fill="#4D4D4D"/>
        <path
            d="M232.337 110.42L233.176 113.173L234.687 113.659C234.855 113.659 234.855 113.821 235.023 113.821C235.527 114.145 236.03 114.631 236.366 115.279C237.709 117.708 238.045 121.271 236.366 121.919C236.198 122.081 236.198 122.243 236.366 122.567C236.534 122.729 236.702 122.891 236.87 123.053C237.038 123.053 237.038 123.053 237.206 123.053C239.053 122.891 240.732 119.652 237.206 113.497C236.87 113.011 236.534 112.525 236.03 112.363L232.337 110.42Z"
            fill="black"/>
        <path
            d="M247.953 99.5565L235.024 106.845C230.994 109.112 224.614 109.112 220.753 106.845C216.723 104.577 216.723 101.014 220.753 98.9087L233.681 91.6205C237.711 89.3531 244.091 89.3531 247.953 91.6205C251.814 93.8879 251.982 97.4511 247.953 99.5565Z"
            fill="#1182BF"/>
        <path
            d="M250.974 106.688V108.146C250.47 109.28 249.63 110.252 248.119 110.899L242.914 113.815V113.491L247.951 110.737C249.966 109.604 250.974 108.146 250.974 106.688Z"
            fill="white"/>
        <path d="M250.974 80.6094V95.6717L217.729 103.446L214.875 89.1933L250.974 80.6094Z" fill="#1182BF"/>
        <path
            d="M247.783 84.9849L233.68 92.9209C229.314 95.3503 222.43 95.3503 218.065 92.9209C213.7 90.4915 213.7 86.6045 218.065 84.1751L232.169 76.239C236.534 73.8096 243.418 73.8096 247.783 76.239C251.981 78.6684 251.981 82.5555 247.783 84.9849Z"
            fill="#E3F1FF"/>
        <path
            d="M225.789 93.7282C222.935 93.7282 220.416 93.0804 218.401 92.1086C216.555 91.1369 215.547 89.8412 215.547 88.5455C215.547 87.2498 216.555 85.9542 218.401 84.9824L232.505 77.0464C234.52 75.9126 237.038 75.4268 239.893 75.4268C242.747 75.4268 245.266 76.0746 247.28 77.0464C249.127 78.0181 250.135 79.3138 250.135 80.6095C250.135 81.9051 249.127 83.2008 247.28 84.1726L233.177 92.1086C231.162 93.0804 228.643 93.7282 225.789 93.7282Z"
            fill="#779CBF"/>
        <path
            d="M232.673 83.8551C236.367 85.9606 236.367 89.1998 232.673 91.3053C228.979 93.4108 222.935 93.4108 219.241 91.3053C215.547 89.1998 215.547 85.9606 219.241 83.8551C222.935 81.9116 228.979 81.9116 232.673 83.8551Z"
            fill="white"/>
        <path
            d="M245.937 77.3717C249.127 79.1533 249.127 82.0686 245.937 84.0121C242.746 85.7936 237.374 85.7936 234.016 84.0121C230.825 82.2305 230.825 79.3152 234.016 77.3717C237.374 75.4282 242.578 75.4282 245.937 77.3717Z"
            fill="#192936"/>
        <path
            d="M230.826 84.8217C233.512 86.2794 233.512 88.8707 230.826 90.3284C228.14 91.786 223.606 91.786 220.92 90.3284C218.233 88.8707 218.233 86.2794 220.92 84.8217C223.606 83.3641 228.14 83.3641 230.826 84.8217Z"
            fill="#192936"/>
    </svg>

)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
