import React from "react";
import {StyledAreaSection2} from "./Section2.styles";
import {Col, Row} from "antd";

import arrFile from "../../../../assets/data/areas.json"
import {MyButton} from "../../../common/MyButton/MyButton";
import {MyBackButton} from "../../../common/MyBackButton/MyBackButton";

export const Section2 = ({setIsModalOpen}) => {

  const arrOfService = arrFile


  return (
    <StyledAreaSection2>
      <Col xs={{
        pull: 1,
        span: 22,
        push: 1,
      }} lg={{
        pull: 3,
        span: 18,
        push: 3,
      }} xl={{
        pull: 4,
        span: 16,
        push: 4,
      }}>
          {arrOfService.map((item, index) => {

            return (
                <Col span={24} key={`area-container-${index}`}>
                  <h1 className="h1-text" style={{textAlign: "center", marginRight: "auto", marginLeft: "auto", marginTop: 80, marginBottom: 60}}>{item.regionName}</h1>
                  <Row justify="start">
                    {item.addressList.map((addressItem, addressIndex) => (
                        <Col style={{position: 'relative'}} key={addressIndex + index + "-area-item"} xs={12} md={8}>
                          <span className="areaName">{addressItem}</span>
                        </Col>
                    ))}
                  </Row>
                </Col>
            )
          })}
      </Col>
    </StyledAreaSection2>
  )
}