import Icon from "@ant-design/icons";


const Svg = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M40.4564 4.25014H9.54365C7.60009 4.25014 6 5.83482 6 7.74139V46.5166C6 48.4314 7.60009 50.0078 9.54365 50.0078H40.4564C42.3999 50.0078 44 48.4231 44 46.5166V7.74964C44 5.83482 42.3999 4.25839 40.4564 4.25839V4.25014ZM41.8973 16.7295V38.6179H8.10273V16.7295H41.8973ZM40.4564 6.33003C41.2187 6.33003 41.8889 6.99857 41.8889 7.74139V14.6496H8.10273V7.74139C8.10273 6.99032 8.78131 6.33003 9.53527 6.33003H40.4564ZM9.54365 47.9362C8.7813 47.9362 8.11111 47.2676 8.11111 46.5248V40.6978H41.8973V46.5248C41.8973 47.2759 41.2187 47.9362 40.4647 47.9362H9.54365Z" fill="white"/>
    <path d="M10.2138 2.17024H19.7557C20.3505 2.17024 20.8532 1.66678 20.8532 1.08903C20.8532 0.51128 20.3505 0.0078125 19.7557 0.0078125H10.2138C9.61905 0.0078125 9.1164 0.51128 9.1164 1.08903C9.1164 1.66678 9.61905 2.17024 10.2138 2.17024Z" fill="white"/>
    <path d="M30.3197 2.17024H39.7778C40.3726 2.17024 40.8752 1.66678 40.8752 1.08903C40.8752 0.51128 40.3726 0.0078125 39.7778 0.0078125H30.3197C29.7332 0.0078125 29.2222 0.51128 29.2222 1.08903C29.2222 1.66678 29.7332 2.17024 30.3197 2.17024Z" fill="white"/>
    <path d="M12.325 11.571H15.5335C16.1283 11.571 16.631 11.0676 16.631 10.4898C16.631 9.91207 16.1283 9.40861 15.5335 9.40861H12.325C11.7302 9.40861 11.2275 9.91207 11.2275 10.4898C11.3113 11.0758 11.7302 11.571 12.325 11.571Z" fill="white"/>
    <path d="M19.7641 11.571H22.8889C23.4837 11.571 23.9863 11.0676 23.9863 10.4898C23.9863 9.91207 23.4837 9.40861 22.8889 9.40861H19.6803C19.0855 9.40861 18.5829 9.91207 18.5829 10.4898C18.6667 11.0758 19.1693 11.571 19.7641 11.571Z" fill="white"/>
    <path d="M27.1111 11.571H30.3197C30.9145 11.571 31.4171 11.0676 31.4171 10.4898C31.4171 9.91207 30.9145 9.40861 30.3197 9.40861H27.1111C26.5163 9.40861 26.0137 9.91207 26.0137 10.4898C26.0974 11.0758 26.5163 11.571 27.1111 11.571Z" fill="white"/>
    <path d="M34.5419 11.571H37.7504C38.3452 11.571 38.8479 11.0676 38.8479 10.4898C38.8479 9.91207 38.3369 9.40861 37.7504 9.40861H34.5419C33.9471 9.40861 33.4444 9.91207 33.4444 10.4898C33.4444 11.0676 33.9555 11.571 34.5419 11.571Z" fill="white"/>
  </svg>
)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
