import styled from 'styled-components';
import {Layout} from "antd";
import {AreaCurrentIcon, AreaIcon, currentTabIcon, currentTabImg} from "../../assets";

export const StyledHeader = styled(Layout.Header)`
  height: 88px;
  padding: 0;
  background-color: #fff;
  box-shadow: ${({$showAdaptiveMenu}) => $showAdaptiveMenu ? "none" : "0 16px 24px -6px rgba(27, 36, 44, 0.16)"};
  border-bottom: ${({$showAdaptiveMenu}) => $showAdaptiveMenu ? "1px solid #091C44" : "none"};
  z-index: ${({$showAdaptiveMenu}) => $showAdaptiveMenu ? "1001" : "99"};
  position: fixed;
  width: 100%;
  
  .adaptive-menu{
    display: none;
      border:none;
      border-radius: 50px;
      width: 48px;
      height: 48px;
      padding: 0;
      
      @media (max-width: 520px){
          width: 36px;
          height: 36px;
          
          svg{
              width: 36px;
              height: 36px;
          }
      }
  }
  
  
  .active{
      color: #003298 !important;

    &:before {
      content: "";
      background: #003399;
      position: absolute;
      border-top-left-radius: 40px;
      border-top-right-radius: 40px;
      bottom: -30px;
      
      height: 8px;
    }
    
    &.main:before{
      left: -16px;
      width: 86px;
    }

    &.brands:before{
      left: -14px;
      width: 93px;
    }

    &.area:before{
      left: -16px;
      width: 73px;
    }

    &.contacts:before{
      left: -11px;
      width: 107px;
    }
  }
    
  .adaptiveLogo{
    display: none;
  }
  
  .areaCurrentIcon {
    position: absolute;
    bottom: -20px;
    left: 8px;

    @media (max-width: 736px) {
      left: 0;
    }
    
    @media (max-width: 597px) {
      bottom: -24px;
      left: 0;
    }

    @media (max-width: 577px) {
      display: none;
    }
  }

  @media (max-width: 938px) {
    .menu-items {
      display: none;
    }
    
    .adaptive-menu{
      display: flex;
      
      margin-left: 24px;
    }
  }

    @media (max-width: 406px) {

        .adaptive-menu{
            

            margin-left: 12px;
        }
    }

    @media (max-width: 350px) {

        .adaptive-menu{


            margin-left: 6px;
        }
    }
  
  @media(max-width: 577px){
    height: 72px;
    
    .adaptiveLogo {
      width: 100px;
      height: 40px;

      svg {
        width: 100px;

        height: 40px;
      }
    }
  }
  
  @media (max-width: 530px) {
    .adaptiveLogo{
      display: flex;
    }
    
    .normalLogo{
      display: none;
    }
  }

  @media(max-width: 379px){
    svg {
      width: 32px;
      height: 32px;
    }

    .adaptiveLogo {
        width: 80px;
      svg {
        width: 80px;

        height: 40px;
      }
    }
  }
  
  
  
  .serviceArea{
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 1px;
    transition: 200ms;
    text-decoration: underline;
    color: #1D67A4;

    @media (max-width: 736px) {
      font-size: 18px;
    }
     
    &:hover{
    transition: 200ms;
      color: #003399;
      cursor: pointer;
    }
  }
`

export const StyledCallUsContainer = styled.div`
  display: flex;
  padding: 8px 16px 8px 8px;
  align-items: center;
  background-color: #003399;
  border-radius: 40px;
  
  &:hover{
    cursor: pointer;
  }
  

  @media(max-width: 528px){
    padding: 4px 10px 4px 8px;
    margin-left: 4px !important;
  }

  @media(max-width: 379px){
    padding: 4px 6px 4px 6px;
    margin-left: 8px !important;
    
    svg{
      width: 24px;
      height: 24px;
    }
  }

  @media(max-width: 325px){
    margin-left: 6px !important;
  }

  .phoneNumber {
    margin-left: 12px;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 1px;
    font-weight: 600;
    color: #fff;


    @media(max-width: 622px){
      font-size: 20px;
    }

    @media(max-width: 466px){
      font-size: 18px;
    }

    @media(max-width: 423px){
      font-size: 16px;
    }

    @media(max-width: 389px){
      font-size: 14px;
    }

    @media(max-width: 360px){
          font-size: 12px !important;
    }
    
  }

  @media (max-width: 379px){
    margin-left: 12px;
    
    .phoneNumber{
      font-size: 16px;
      line-height: 24px;
    }
  }
`