import Icon from "@ant-design/icons";


const Svg = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="16" fill="#0050E3"/>
            <path
                d="M16 16.7996L8 11.8076V11.1996C8 10.3116 8.712 9.59961 9.6 9.59961H22.4C22.8243 9.59961 23.2313 9.76818 23.5314 10.0682C23.8314 10.3683 24 10.7753 24 11.1996V11.7996L16 16.7996ZM24 20.7996C24 21.224 23.8314 21.6309 23.5314 21.931C23.2313 22.231 22.8243 22.3996 22.4 22.3996H9.6C8.712 22.3996 8 21.6796 8 20.7996V13.6876L9.6 14.6876V20.7996H22.4V14.6876L24 13.6876V20.7996Z"
                fill="white"/>
    </svg>
)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
