import Icon from "@ant-design/icons";


const Svg = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M49.5791 31.5089L32.2743 16.1758V1.09064C32.2743 0.503762 31.7709 0.0078125 31.1933 0.0078125H18.716C18.1301 0.0078125 17.6349 0.512028 17.6349 1.09064V16.2584L0.330087 31.5089C0.0825215 31.6742 0 32.0131 0 32.2611V48.925C0 49.5119 0.495131 50.0078 1.08104 50.0078H48.919C49.5049 50.0078 50 49.5119 50 48.925V32.2611C49.9175 32.0131 49.7524 31.6742 49.5874 31.5089H49.5791ZM19.797 2.0908H30.1947V15.6716H19.797V2.0908ZM2.0713 32.757L19.1286 17.6719H30.8632L47.8379 32.757V39.5929H2.0713V32.757ZM47.8297 47.9248H43.6706V45.8418C43.6706 45.255 43.1672 44.759 42.5895 44.759C42.0119 44.759 41.5085 45.255 41.5085 45.8418V47.9248H39.5115V45.8418C39.5115 45.255 39.0081 44.759 38.4304 44.759C37.8528 44.759 37.3494 45.255 37.3494 45.8418V47.9248H35.3524V45.8418C35.3524 45.255 34.849 44.759 34.2713 44.759C33.6937 44.759 33.1903 45.255 33.1903 45.8418V47.9248H2.0713V41.6759H47.8297V47.9248Z" fill="white"/>
    <path d="M40.1799 31.5089L31.8617 24.1771C31.4491 23.7638 30.7807 23.8465 30.3598 24.2598C29.9472 24.673 30.0297 25.3426 30.4423 25.7641L38.7605 33.0959C39.1731 33.5092 39.8415 33.4266 40.2542 33.0133C40.6668 32.6 40.5842 31.9304 40.1716 31.5089H40.1799Z" fill="white"/>
  </svg>
)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
