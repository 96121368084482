import Icon from "@ant-design/icons";


const Svg = () => (
    <svg width="57" height="34" viewBox="0 0 57 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M34.1055 17.0003C34.1055 26.3895 26.4712 34 17.0532 34C7.63484 34 0 26.3895 0 17.0003C0 7.61128 7.63484 0 17.0532 0C26.4712 0 34.1055 7.61128 34.1055 17.0003Z"
              fill="#CC0000"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M39.9473 0C35.5401 0 31.5258 1.66647 28.4989 4.40031C27.8831 4.9575 27.3083 5.55929 26.7777 6.20003H30.2231C30.6936 6.7712 31.1272 7.37164 31.5231 8.00056H25.4774C25.1158 8.57844 24.787 9.17889 24.4949 9.80109H32.5051C32.7789 10.3838 33.0198 10.984 33.2274 11.6008H23.7727C23.5759 12.187 23.4099 12.7877 23.2773 13.4003H33.7221C33.9722 14.5609 34.1053 15.765 34.1053 17.0003C34.1053 18.8876 33.7968 20.7042 33.2274 22.4008H23.7727C23.9802 23.0171 24.2211 23.6181 24.4943 24.2016H32.5051C32.2125 24.8222 31.8853 25.4231 31.5215 26.0018H25.4774C25.8717 26.6289 26.3061 27.2301 26.7777 27.8005H30.222C29.6925 28.4418 29.1171 29.0436 28.4995 29.6007C31.5261 32.3346 35.5401 34 39.9473 34C49.3651 34 56.9996 26.3895 56.9996 17.0003C56.9996 7.61209 49.3651 0 39.9473 0Z"
              fill="#FF9900"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M13.0725 18.861C12.8782 18.839 12.7925 18.832 12.6591 18.832C11.6097 18.832 11.0791 19.191 11.0791 19.8999C11.0791 20.3365 11.3381 20.614 11.7423 20.614C12.4966 20.614 13.0402 19.8983 13.0725 18.861ZM14.4176 21.9858C14.1708 21.9858 12.8831 21.9858 12.8831 21.9858L12.9184 21.259C12.4506 21.8329 11.8262 22.1069 10.9781 22.1069C9.97511 22.1069 9.28711 21.3262 9.28711 20.1917C9.28711 18.4838 10.4834 17.4879 12.5371 17.4879C12.7476 17.4879 13.0165 17.5073 13.292 17.5419C13.3491 17.3103 13.3642 17.212 13.3642 17.0871C13.3642 16.6228 13.0419 16.4488 12.1771 16.4488C11.2713 16.4396 10.5243 16.6645 10.2174 16.765C10.2368 16.6484 10.4742 15.1877 10.4742 15.1877C11.3964 14.9174 12.0049 14.8164 12.6897 14.8164C14.2796 14.8164 15.1218 15.5278 15.1199 16.8727C15.1231 17.2335 15.063 17.6771 14.9701 18.2622C14.8094 19.2777 14.4645 21.4557 14.4176 21.9858Z"
              fill="#000066"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M8.5127 21.9863H6.66105L7.72229 15.358L5.35377 21.9863H4.0923L3.93627 15.3951L2.82167 21.9863H1.08887L2.53628 13.3633H5.19855L5.35943 18.1899L6.98363 13.3633H9.94449L8.5127 21.9863Z"
              fill="#000066"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M42.2374 18.861C42.0431 18.839 41.9585 18.832 41.8254 18.832C40.7752 18.832 40.2451 19.191 40.2451 19.8999C40.2451 20.3365 40.503 20.614 40.9083 20.614C41.6621 20.614 42.2059 19.8983 42.2374 18.861ZM43.5834 21.9858C43.336 21.9858 42.0494 21.9858 42.0494 21.9858L42.0842 21.259C41.6164 21.8329 40.9922 22.1069 40.1447 22.1069C39.1403 22.1069 38.4531 21.3262 38.4531 20.1917C38.4531 18.4838 39.6499 17.4879 41.7031 17.4879C41.9133 17.4879 42.1818 17.5073 42.4574 17.5419C42.5148 17.3103 42.5297 17.212 42.5297 17.0871C42.5297 16.6228 42.2074 16.4488 41.3426 16.4488C40.4368 16.4396 39.6901 16.6645 39.382 16.765C39.4025 16.6484 39.6405 15.1877 39.6405 15.1877C40.5621 14.9174 41.1712 14.8164 41.8549 14.8164C43.4459 14.8164 44.287 15.5278 44.2854 16.8727C44.2886 17.2335 44.229 17.6771 44.1355 18.2622C43.9755 19.2777 43.6297 21.4557 43.5834 21.9858Z"
              fill="#000066"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M22.6463 21.8796C22.1394 22.0387 21.7446 22.1075 21.3161 22.1075C20.37 22.1075 19.8534 21.5653 19.8534 20.5667C19.8402 20.2567 19.9894 19.4413 20.1069 18.6977C20.2142 18.0424 20.91 13.9121 20.91 13.9121H22.7498L22.535 14.9736H23.6467L23.3958 16.6591H22.2804C22.0667 17.9925 21.7619 19.6538 21.7584 19.8741C21.7584 20.2357 21.9519 20.3937 22.3925 20.3937C22.6035 20.3937 22.7668 20.3722 22.8916 20.3276L22.6463 21.8796Z"
              fill="#000066"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M28.2868 21.8224C27.6546 22.015 27.0453 22.1091 26.3988 22.1069C24.3386 22.1042 23.2646 21.0323 23.2646 18.9784C23.2646 16.5815 24.6307 14.8164 26.4856 14.8164C28.0025 14.8164 28.9718 15.8045 28.9718 17.3536C28.9718 17.8686 28.9056 18.3705 28.7447 19.0786H25.0802C24.9565 20.096 25.6092 20.5197 26.6804 20.5197C27.3382 20.5197 27.9327 20.3843 28.5932 20.0788L28.2868 21.8224ZM27.2544 17.666C27.2649 17.5196 27.4492 16.4141 26.3974 16.4141C25.8121 16.4141 25.3925 16.8595 25.2225 17.666H27.2544Z"
              fill="#000066"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M15.5281 17.1902C15.5281 18.0765 15.9593 18.6883 16.9375 19.1479C17.6869 19.4996 17.8036 19.6036 17.8036 19.9219C17.8036 20.3585 17.4727 20.5568 16.7405 20.5568C16.1878 20.5568 15.6741 20.4703 15.0815 20.2792C15.0815 20.2792 14.8387 21.8256 14.8271 21.8995C15.2478 21.9903 15.6229 22.0749 16.7537 22.1069C18.707 22.1069 19.6092 21.3654 19.6092 19.7629C19.6092 18.7995 19.2319 18.234 18.3043 17.8084C17.5285 17.4535 17.4387 17.374 17.4387 17.0468C17.4387 16.6677 17.7468 16.474 18.3448 16.474C18.7083 16.474 19.2044 16.5127 19.6752 16.5791L19.9385 14.9526C19.4594 14.8766 18.7323 14.8164 18.3092 14.8164C16.2385 14.8164 15.5213 15.8945 15.5281 17.1902Z"
              fill="#000066"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M37.2909 15.002C37.8051 15.002 38.2853 15.1353 38.9453 15.4676L39.2484 13.5961C38.9763 13.4898 38.022 12.8662 37.2135 12.8662C35.975 12.8662 34.9267 13.4798 34.1907 14.4902C33.116 14.1356 32.6738 14.8524 32.1326 15.567L31.6516 15.6788C31.688 15.4437 31.7211 15.2102 31.7106 14.9735H30.0096C29.7779 17.1446 29.3663 19.3427 29.0443 21.5145L28.96 21.9863H30.8121C31.1212 19.9837 31.2902 18.7019 31.3932 17.8337L32.0906 17.4484C32.1954 17.0613 32.5207 16.9312 33.175 16.9471C33.0896 17.4043 33.0432 17.886 33.0432 18.3844C33.0432 20.679 34.2866 22.1074 36.2787 22.1074C36.7921 22.1074 37.2329 22.0403 37.9156 21.8549L38.24 19.8899C37.6261 20.1906 37.1235 20.3322 36.6678 20.3322C35.5915 20.3322 34.9402 19.5399 34.9402 18.2313C34.9402 16.3321 35.9081 15.002 37.2909 15.002Z"
              fill="#000066"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M52.9648 13.3633L52.5533 15.8545C52.048 15.1907 51.5042 14.7098 50.7863 14.7098C49.8523 14.7098 49.002 15.4158 48.4453 16.4555C47.6699 16.2951 46.8688 16.0224 46.8688 16.0224L46.8682 16.0292C46.9299 15.448 46.9561 15.0945 46.9499 14.9736H45.2491C45.0182 17.1446 44.6061 19.3427 44.2844 21.5146L44.1992 21.9863H46.0519C46.302 20.3679 46.4931 19.0225 46.634 17.957C47.2673 17.3864 47.5842 16.8902 48.2227 16.9224C47.9394 17.6054 47.7742 18.3914 47.7742 19.1979C47.7742 20.9504 48.663 22.1075 50.0094 22.1075C50.6879 22.1075 51.2086 21.874 51.7163 21.3335L51.629 21.9858H53.3806L54.7909 13.3633H52.9648ZM50.6503 20.367C50.0202 20.367 49.7012 19.9011 49.7012 18.9845C49.7012 17.606 50.2975 16.6289 51.137 16.6289C51.7733 16.6289 52.1182 17.1114 52.1182 18.0023C52.1182 19.3926 51.5119 20.367 50.6503 20.367Z"
              fill="#000066"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M9.04466 21.4748H7.19355L8.25452 14.8465L5.88654 21.4748H4.62453L4.46877 14.8849L3.3539 21.4748H1.62109L3.06851 12.8525H5.73077L5.80515 18.1902L7.60155 12.8525H10.4767L9.04466 21.4748Z"
              fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M13.6028 18.35C13.4087 18.3275 13.323 18.3213 13.1896 18.3213C12.1403 18.3213 11.6094 18.6797 11.6094 19.3881C11.6094 19.8241 11.8686 20.1033 12.2731 20.1033C13.0271 20.1033 13.5707 19.3876 13.6028 18.35ZM14.9483 21.474C14.7017 21.474 13.4147 21.474 13.4147 21.474L13.4497 20.7479C12.9819 21.3214 12.3575 21.5957 11.5094 21.5957C10.5061 21.5957 9.81836 20.8139 9.81836 19.6797C9.81836 17.9716 11.0149 16.977 13.0686 16.977C13.2786 16.977 13.5475 16.9961 13.8232 17.0315C13.8803 16.7994 13.8954 16.7008 13.8954 16.5754C13.8954 16.1114 13.5731 15.9387 12.7083 15.9387C11.8023 15.9279 11.0556 16.1528 10.7484 16.253C10.768 16.1369 11.0052 14.6762 11.0052 14.6762C11.9276 14.4065 12.5361 14.3047 13.2209 14.3047C14.8106 14.3047 15.6525 15.0169 15.6511 16.3612C15.6541 16.7215 15.5945 17.1672 15.5013 17.7507C15.3404 18.7654 14.9955 20.9448 14.9483 21.474Z"
              fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M39.7806 13.0846L39.4774 14.9558C38.8164 14.6245 38.3375 14.4904 37.8238 14.4904C36.4416 14.4904 35.472 15.8222 35.472 17.7216C35.472 19.0302 36.1236 19.8208 37.2 19.8208C37.6559 19.8208 38.1596 19.6803 38.7722 19.3786L38.4469 21.3436C37.7654 21.5287 37.3242 21.5964 36.8111 21.5964C34.8177 21.5964 33.5762 20.1677 33.5762 17.8736C33.5762 14.7911 35.2925 12.6367 37.7459 12.6367C38.5544 12.6367 39.5092 12.9782 39.7806 13.0846Z"
              fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M42.7684 18.35C42.5755 18.3275 42.4895 18.3213 42.3564 18.3213C41.3075 18.3213 40.7764 18.6797 40.7764 19.3881C40.7764 19.8241 41.0353 20.1033 41.4396 20.1033C42.1933 20.1033 42.7374 19.3876 42.7684 18.35ZM44.117 21.474C43.8707 21.474 42.5833 21.474 42.5833 21.474L42.6181 20.7479C42.1503 21.3214 41.5259 21.5957 40.6786 21.5957C39.6742 21.5957 38.9873 20.8139 38.9873 19.6797C38.9873 17.9716 40.1827 16.977 42.2371 16.977C42.447 16.977 42.7154 16.9961 42.9908 17.0315C43.0482 16.7994 43.0633 16.7008 43.0633 16.5754C43.0633 16.1114 42.7418 15.9387 41.8765 15.9387C40.9713 15.9279 40.2248 16.1528 39.917 16.253C39.9364 16.1369 40.1739 14.6762 40.1739 14.6762C41.0963 14.4065 41.7043 14.3047 42.389 14.3047C43.9787 14.3047 44.8214 15.0169 44.8193 16.3612C44.8231 16.7215 44.763 17.1672 44.6697 17.7507C44.5094 18.7654 44.1644 20.9448 44.117 21.474Z"
              fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M23.1775 21.3683C22.6712 21.5274 22.2758 21.5964 21.8479 21.5964C20.9015 21.5964 20.3846 21.054 20.3846 20.0565C20.3716 19.7454 20.5212 18.9314 20.639 18.1869C20.7454 17.5311 21.4415 13.4014 21.4415 13.4014H23.2818L23.0665 14.4626H24.0113L23.7596 16.1476H22.8118C22.5981 17.4822 22.2934 19.1425 22.2896 19.3628C22.2896 19.7255 22.4839 19.8824 22.924 19.8824C23.135 19.8824 23.298 19.8617 23.4233 19.8161L23.1775 21.3683Z"
              fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M28.8203 21.312C28.1878 21.5052 27.5771 21.5963 26.9314 21.5957C24.8709 21.5944 23.7979 20.5211 23.7979 18.4675C23.7979 16.0698 25.1636 14.3047 27.0171 14.3047C28.5357 14.3047 29.5045 15.2928 29.5045 16.8438C29.5045 17.3577 29.4371 17.8593 29.2781 18.5677H25.6131C25.4889 19.5851 26.1424 20.0096 27.2125 20.0096C27.8709 20.0096 28.4651 19.8737 29.1264 19.5674L28.8203 21.312ZM27.7864 17.1521C27.7964 17.0054 27.9815 15.9004 26.9299 15.9004C26.3441 15.9004 25.9245 16.3466 25.7539 17.1521H27.7864Z"
              fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M16.0598 16.6785C16.0598 17.5664 16.4915 18.1768 17.47 18.637C18.2194 18.9884 18.3356 19.0924 18.3356 19.411C18.3356 19.8481 18.0049 20.0453 17.2722 20.0453C16.72 20.0453 16.2061 19.9599 15.6132 19.7694C15.6132 19.7694 15.3707 21.3147 15.3594 21.3875C15.7798 21.4794 16.1552 21.5632 17.2854 21.5957C19.2392 21.5957 20.1414 20.8545 20.1414 19.252C20.1414 18.288 19.7636 17.7228 18.8363 17.2983C18.0602 16.9423 17.971 16.8636 17.971 16.5359C17.971 16.1568 18.2787 15.9618 18.8773 15.9618C19.24 15.9618 19.7367 16.0021 20.2072 16.0681L20.4707 14.442C19.9913 14.3665 19.2648 14.3047 18.8414 14.3047C16.7701 14.3047 16.0536 15.3831 16.0598 16.6785Z"
              fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M53.9133 21.4748H52.1622L52.249 20.8222C51.741 21.363 51.2206 21.5965 50.542 21.5965C49.1968 21.5965 48.3066 20.4407 48.3066 18.6864C48.3066 16.3539 49.6867 14.3879 51.319 14.3879C52.0369 14.3879 52.5804 14.6802 53.0868 15.3443L53.4975 12.8525H55.3233L53.9133 21.4748ZM51.1822 19.8543C52.0448 19.8543 52.6501 18.8804 52.6501 17.4915C52.6501 16.6003 52.3054 16.1162 51.6691 16.1162C50.8294 16.1162 50.2333 17.0936 50.2333 18.4726C50.2333 19.3903 50.5526 19.8543 51.1822 19.8543Z"
              fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M45.7821 14.462C45.5506 16.6325 45.1388 18.8306 44.8168 21.0027L44.7324 21.4742H46.5841C47.2465 17.1859 47.4057 16.349 48.4449 16.4535C48.6101 15.5758 48.9181 14.8072 49.1474 14.4198C48.3721 14.2586 47.9391 14.6955 47.3715 15.5256C47.4168 15.1664 47.4984 14.818 47.4831 14.462H45.7821Z"
              fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M30.5436 14.462C30.3111 16.6325 29.8987 18.8306 29.5772 21.0027L29.4932 21.4742H31.3453C32.008 17.1859 32.1667 16.349 33.2043 16.4535C33.3705 15.5758 33.6791 14.8072 33.9079 14.4198C33.1334 14.2586 32.6995 14.6955 32.1331 15.5256C32.1778 15.1664 32.26 14.818 32.2438 14.462H30.5436Z"
              fill="white"/>
    </svg>
)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
