import React from "react";
import {StyledCarousel, StyledSection9Container} from "./Section9.styles";
import {Col, Row, Grid, Image} from "antd";

import ShirleyImage from '../../../../assets/images/section9/ShirleyImage.png'
import KathiImage from '../../../../assets/images/section9/KathiImage.png'
import PatImage from '../../../../assets/images/section9/PatImage.png'
import ErikImage from '../../../../assets/images/section9/ErikImage.png'
import ArtsiomImage from '../../../../assets/images/section9/ArtsiomImage.png'
import ClaudioImage from '../../../../assets/images/section9/ClaudioImage.png'

const {useBreakpoint} = Grid;

export const Section9 = () => {

  const screens = useBreakpoint()

    const reviews = [
        {
            icon: KathiImage,
            userName: "Kathi N.",
            comment: "This appliance repair service did a great job. They were timely and communicative. CERIK was our technician and we requested that he be sent out on a second repair as well. He was kind and explained what he was doing as well as answering our questions. Great job all around."
        },
        {
            icon: ShirleyImage,
            userName: "Jason M P.",
            comment: "I submitted a request to Axis on Friday and Alex came out the next day. He figured out what was wrong and took our circuit board to be repaired. He was back the very next day with the repaired board and had our fridge working again less than 48 hours after my first call. Thank you Alex!"
        },
        {
            icon: ErikImage,
            userName: "Erik F.",
            comment: "Timur did a good job at what he does, very understanding with what needs to be addressed very compliment and straight forward ! would definitely recommend him again"
        },
        {
            icon: PatImage,
            userName: "Pat R.",
            comment: "Artem quickly diagnosed the problem with my Kenmore fridge and ordered the part. When it arrived, he quickly installed and explained everything to me. Artem was pleasant, efficient and solved my problem. Job well done!"
        },
        {
            icon: ArtsiomImage,
            userName: "Artsiom H.",
            comment: "The experiment with Ilya C was great! I am a realtor and needed the job done quickly, guy arrived the same day, charged a reasonable price, order a part that was needed day later it was done!"
        },
        {
            icon: ClaudioImage,
            userName: "Claudio S.",
            comment: "My LG washer stopped working.I contacted HD warranty and they sent me the part and scheduled an appointment (within a week) the day after Christmas. Nikolai called and arrived promptly and resolved my issue. He was a pleasant fellow and I am pleased to have my washer back up and running.  No complaints here."
        },

    ]



  const settings = {
    slidesToShow: !screens.md ? 1 : 2,
  }

    return (
        <StyledSection9Container>
            <Col xs= {{
                pull: 1,
                span: 22,
                push: 1,
            }}lg={{
                pull: 3,
                span: 18,
                push: 3,
            }} xl={{
                pull: 4,
                span: 16,
                push: 4,
            }}>
                <Row className="textBlock" style={{flexDirection: "column", marginTop: 50, marginBottom: 30}} align="middle">
                    <h1 className="title">Testimonials</h1>
                    <span className="subtitle">Read reviews about our repair, installation and maintenance services. Our trained experts are {!screens.lg ? null : <br />}  always ready to help! ♥</span>
                </Row>
              <StyledCarousel {...settings} dots={true} >
                {
                  reviews.map((reviewItem, index) => (
                    <div key={index + "-user-review"} className="item">
                      <span className="item_text">{reviewItem.comment}</span>

                      <Row align="middle" className="userInfo">
                        <Image src={reviewItem.icon} preview={false}/>
                        <span>{reviewItem.userName}</span>
                      </Row>
                    </div>
                  ))
                }
              </StyledCarousel>
            </Col>
        </StyledSection9Container>
    )
}