import styled from "styled-components";
import {Button} from "antd";

export const StyledMyButton = styled(Button)`
  height: 56px;
  border: none;
  border-radius: 40px;
  text-transform: uppercase;

  span:not(.ant-btn-loading-icon) {
    font-weight: 600; 
    letter-spacing: 2px;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    
    @media (max-width: 500px) {
      padding-left: 0 !important;
      padding-right: 0 !important;
      font-size: 14px;
      line-height: 22px;
    }
  }
  
  @media (max-width: 377px){
    height: 40px;
  }
`