import Icon from "@ant-design/icons";


const Svg = () => (
    <svg width="140" height="55" viewBox="0 0 140 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4027_3825)">
                    <path
                        d="M97.0859 22.8379C95.3938 22.9171 92.4038 23.7946 91.7216 26.5988L89.3375 39.8611C88.7978 42.7247 90.2945 45.9677 93.5097 45.9974H111.391C113.689 45.9644 116.328 45.2617 116.953 41.8406L119.536 27.9845C120.02 25.1967 118.086 22.9171 115.364 22.8379H97.0859Z"
                        fill="black"/>
                    <path
                        d="M99.2712 29.3701C98.0328 29.314 97.6818 29.7528 97.4831 30.3598L96.0924 37.4858C96.0096 38.5151 96.6255 39.5675 97.6818 39.6632H109.006C110.082 39.5675 110.963 38.8681 111.192 37.8817L112.384 31.3495C112.722 30.1915 112.106 29.3173 110.993 29.3701H99.2712Z"
                        fill="white"/>
                    <path
                        d="M88.3476 29.3697H72.0562C70.8575 29.3136 70.5032 29.7524 70.2681 30.3594L69.0761 37.4854C68.8344 38.5147 69.4503 39.5671 70.6655 39.6628H86.3609L85.1688 45.997H66.2946C63.1191 45.9641 61.6224 42.7211 62.1224 39.8608L64.7052 26.5985C65.2284 23.791 68.2185 22.9167 69.8708 22.8375H89.5397L88.3476 29.3697ZM53.7748 29.3697H37.8807L39.0728 22.8375H56.9536C60.2119 22.9167 61.7052 26.1597 61.1258 28.9738L58.7416 42.2361C58.1026 45.0898 55.1125 45.9641 53.3774 45.997H36.49C33.2085 45.9641 31.7152 42.7211 32.3178 39.8608L33.1125 34.9121C33.7383 32.0321 36.7284 31.1545 38.4768 31.1512H53.3774C54.4966 31.1545 55.3774 29.3169 53.7748 29.3697ZM31.1258 29.3697H34.7019L35.894 22.8375H32.3178L35.0993 7.00195H28.7417L25.9602 22.8375H7.88073C6.11914 22.9167 3.12907 23.7943 2.51649 26.5985L0.132384 39.8608C-0.476888 42.7244 1.01649 45.9674 4.30457 45.997H24.9668C26.437 45.9641 28.2814 44.651 28.543 42.632L31.1258 29.3697Z"
                        fill="black"/>
                    <path
                        d="M8.48048 39.6627C7.35465 39.567 6.73876 38.5146 6.89107 37.4853L8.27849 30.3593C8.40763 29.7523 8.75862 29.3168 9.86789 29.3696H24.7686L22.7818 39.6627H8.48048Z"
                        fill="white"/>
                    <path
                        d="M130.066 24.8173L131.655 23.4317C132.423 22.9171 133.301 22.9171 134.238 22.8379H140L138.808 29.3701H132.45C130.841 29.314 128.642 30.1058 128.278 32.3392L125.695 45.9974H119.139L123.51 22.8379H129.271L130.066 24.8173Z"
                        fill="black"/>
                    <path
                        d="M39.6684 39.6637C38.6618 39.568 38.4863 37.5523 40.0658 37.4863H53.1783L52.781 39.6637H39.6684Z"
                        fill="white"/>
            </g>
            <defs>
                    <clipPath id="clip0_4027_3825">
                            <rect width="140" height="55" fill="white"/>
                    </clipPath>
            </defs>
    </svg>
)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
