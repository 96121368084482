import Icon from "@ant-design/icons";


const Svg = () => (
    <svg width="99" height="24" viewBox="0 0 99 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4252_13252)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M8.70878 6H15.3084C18.8518 6 20.1856 7.8048 19.9795 10.4563C19.6388 14.8337 17.0085 17.2556 13.5196 17.2556H11.7581C11.2794 17.2556 10.9575 17.5744 10.828 18.4382L10.0801 23.4601C10.0307 23.7858 9.86039 23.9743 9.60485 24H5.4584C5.06828 24 4.93029 23.7001 5.03251 23.0505L7.56059 6.95125C7.65939 6.3068 8.01032 6 8.70878 6Z"
                          fill="#009EE3"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M37.9532 5C40.2388 5 42.3477 6.26252 42.0592 9.40902C41.7095 13.1485 39.7421 15.2177 36.6381 15.2266H33.9258C33.5358 15.2266 33.347 15.5506 33.2455 16.2148L32.7209 19.6106C32.6422 20.1235 32.3834 20.3764 32.0022 20.3764H29.4787C29.0765 20.3764 28.9366 20.1146 29.0258 19.5287L31.1086 5.91884C31.2117 5.2493 31.4583 5 31.9077 5H37.9479H37.9532ZM33.8436 12.2866H35.8984C37.1837 12.2368 38.0371 11.3304 38.1228 9.69571C38.1752 8.68605 37.5055 7.96308 36.4405 7.96843L34.5064 7.97733L33.8436 12.2866ZM48.9195 19.3346C49.1504 19.121 49.3847 19.0106 49.3515 19.2741L49.2693 19.9045C49.2273 20.2339 49.355 20.4084 49.6558 20.4084H51.8977C52.2754 20.4084 52.459 20.2535 52.5517 19.6587L53.9332 10.83C54.0031 10.3866 53.8965 10.1694 53.566 10.1694H51.1002C50.8781 10.1694 50.7697 10.2958 50.712 10.6413L50.6211 11.1844C50.5738 11.4675 50.4462 11.5174 50.3273 11.2325C49.9093 10.2246 48.8426 9.77228 47.3544 9.80789C43.8971 9.8809 41.5661 12.5537 41.316 15.9798C41.1236 18.6295 42.9878 20.7111 45.4465 20.7111C47.2302 20.7111 48.0277 20.1769 48.9265 19.34L48.9195 19.3346ZM47.0414 17.976C45.5532 17.976 44.5162 16.7669 44.7313 15.2853C44.9464 13.8038 46.3384 12.5947 47.8266 12.5947C49.3148 12.5947 50.3518 13.8038 50.1367 15.2853C49.9216 16.7669 48.5313 17.976 47.0414 17.976ZM58.3208 10.1409H56.0474C55.5788 10.1409 55.3882 10.497 55.5368 10.9351L58.3593 19.3507L55.591 23.3555C55.3584 23.6902 55.5385 23.9947 55.8656 23.9947H58.4205C58.5694 24.0123 58.7201 23.9858 58.8547 23.9186C58.9892 23.8513 59.102 23.7461 59.1794 23.6154L67.8602 10.9369C68.1278 10.5469 68.0019 10.1373 67.5629 10.1373H65.1444C64.73 10.1373 64.5638 10.3047 64.326 10.6555L60.7061 15.9976L59.0885 10.643C58.9941 10.319 58.758 10.1409 58.3225 10.1409H58.3208Z"
                          fill="#113984"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M76.9259 5.00363C79.205 5.00363 81.308 6.28907 81.0203 9.4927C80.6716 13.3001 78.7098 15.4068 75.6146 15.4159H72.9118C72.5229 15.4159 72.3346 15.7458 72.2334 16.4221L71.7103 19.8795C71.6318 20.4017 71.3738 20.6592 70.9936 20.6592H68.4774C68.0763 20.6592 67.9368 20.3926 68.0257 19.7962L70.106 5.93552C70.2089 5.25383 70.4548 5 70.9029 5H76.9259V5.00363ZM72.8281 12.4225H74.877C76.1587 12.3718 77.0096 11.449 77.0951 9.78459C77.1474 8.7566 76.4795 8.02051 75.4176 8.02595L73.489 8.03502L72.8281 12.4225ZM87.8611 19.5985C88.0913 19.381 88.3249 19.2686 88.2918 19.5369L88.2099 20.1787C88.168 20.5141 88.2953 20.6918 88.5952 20.6918H90.8307C91.2074 20.6918 91.3905 20.5341 91.4829 19.9285L92.8605 10.9395C92.9302 10.488 92.8239 10.2669 92.4943 10.2669H90.0391C89.8176 10.2669 89.7095 10.3956 89.652 10.7473L89.5613 11.3003C89.5142 11.5886 89.3869 11.6393 89.2683 11.3492C88.8516 10.3231 87.7879 9.86255 86.3039 9.89881C82.8565 9.97315 80.532 12.6945 80.2827 16.1828C80.0909 18.8806 81.9497 21 84.4015 21C86.1801 21 86.9753 20.4561 87.8716 19.604L87.8611 19.5985ZM85.99 18.2152C84.5061 18.2152 83.472 16.9841 83.6865 15.4757C83.901 13.9673 85.2891 12.7362 86.773 12.7362C88.2569 12.7362 89.291 13.9673 89.0765 15.4757C88.862 16.9841 87.474 18.2152 85.99 18.2152ZM96.301 20.7063H93.7202C93.6753 20.7084 93.6305 20.7001 93.589 20.6821C93.5476 20.6642 93.5104 20.6369 93.4803 20.6022C93.4501 20.5676 93.4277 20.5265 93.4146 20.4818C93.4015 20.4371 93.3981 20.3899 93.4046 20.3437L95.6715 5.41156C95.6931 5.30965 95.7473 5.21836 95.8251 5.15252C95.903 5.08669 96.0001 5.05018 96.1004 5.04896H98.6812C98.7261 5.04687 98.7709 5.05511 98.8123 5.0731C98.8538 5.09109 98.8909 5.11838 98.9211 5.15302C98.9512 5.18766 98.9737 5.22879 98.9868 5.27349C98.9999 5.31818 99.0033 5.36533 98.9968 5.41156L96.7299 20.3437C96.709 20.4463 96.6551 20.5384 96.5772 20.6049C96.4992 20.6715 96.4018 20.7085 96.301 20.7099V20.7063Z"
                          fill="#009EE3"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M4.33535 0H10.9968C12.8726 0 15.0989 0.0603151 16.5864 1.37863C17.581 2.25923 18.1032 3.66027 17.983 5.16987C17.5742 10.2725 14.532 13.1315 10.4506 13.1315H7.16622C6.60623 13.1315 6.23691 13.5037 6.07888 14.5101L5.16159 20.3693C5.10147 20.7484 4.93828 20.9724 4.64626 21H0.535654C0.0804463 21 -0.0810251 20.6553 0.0375006 19.8937L2.99205 1.11324C3.11058 0.358443 3.52456 0 4.33535 0Z"
                          fill="#113984"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M6 14L7.19611 6.91844C7.30035 6.29734 7.66431 6 8.38869 6H15.2332C16.3657 6 17.2827 6.16519 18 6.47078C17.3127 10.8251 14.3004 13.2434 10.3569 13.2434H6.9841C6.5318 13.2451 6.19965 13.4565 6 14Z"
                          fill="#172C70"/>
            </g>
            <defs>
                    <clipPath id="clip0_4252_13252">
                            <rect width="99" height="24" fill="white"/>
                    </clipPath>
            </defs>
    </svg>

)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
