import styled from 'styled-components'

export const StyledSection8Container = styled.div`
  position: relative;
  height: 680px;
  
  @media(max-width: 604px){
    height: 500px;
  }
  
  .anticon{
    padding-left: 0 !important;
    padding-right: 0 !important;
    position: relative;
    left: 10px;
    
    @media(max-width: 500px){
      top: 1px;
      left: 0px;
    }
  }
  
  .leaflet-container {
    width: 100% !important;
    height: 100% !important;

    .leaflet-pane{
      z-index: 2 !important;
    }

    .leaflet-control, .leaflet-top{
      z-index: 3 !important;
    }
  }
`