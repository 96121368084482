import styled from "styled-components";

export const StyledTermsAndConditions = styled.div`
  margin-top: 88px;
  background-color: #fff !important;
  background: #fff !important;

  h4,p,span{
    color: #1D1D1f;
  }

  .ant-btn:not(.backButton) {
    margin-top: 42px;
    background-color: #fff;
    border: 1px solid rgba(0, 51, 153, 1);
    transition: 200ms;

    span{
      transition: 200ms;
      color: rgba(0, 51, 153, 1);
    }

    &:hover{
      transition: 200ms;
      background-color: rgba(0, 51, 153, 1) !important;
      border-color: rgba(0, 51, 153, 1) !important;

      span{
        transition: 200ms;
        color: #fff;
      }
    }
  }
  
  .h1-text-adaptive{
    display: none;
  }
  
  @media (max-width: 530px){
    .h1-text-adaptive{
      display: block;
    }

    .h1-text{
      display: none;
    }
  }
  
  @media (max-width: 506px) {
    margin-top: 72px ;
  }

  .h1-row{
    padding-top: 80px;
    padding-bottom: 56px;

    @media (max-width: 506px) {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    @media (max-width: 411px) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  h1 {
    font-size: 48px;
    font-weight: 600;
    line-height: 56px;
    
    
    @media (min-width: 659px) and (max-width: 1024px){
        font-size: 44px;
    }

    @media (max-width: 658px) {
      font-size: 40px !important;
    }
    
    @media (max-width: 506px) {
      font-size: 32px;
      margin-top: 40px;
      margin-bottom: 40px;
    }

    @media (max-width: 411px) {
      font-size: 28px !important;
      line-height: 40px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    
  }

  .termsTitleContainer {
    margin-bottom: 32px;

    @media (max-width: 411px) {
      margin-bottom: 18px;
    }
  }

  .ant-divider {
    border-color: rgba(229, 231, 232, 1);
    margin-top: 40px;
    margin-bottom: 40px;
    
    @media (max-width: 459px) {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  .termsTitle {
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;

    @media(max-width: 1024px){
      font-size: 28px;
    }
    
    @media (max-width: 506px) {
      font-size: 24px;
    }

    @media (max-width: 411px) {
      font-size: 20px;
    }
  }

  .termsText {
    font-size: 18px;
    line-height: 28px;

    @media (max-width: 506px) {
      font-size: 16px;
    }
  }

  .extraText {
    padding-top: 28px;
  }

  .infoContainer {
    padding-top: 40px;
    padding-bottom: 40px;

    .infoText {
      margin-left: 8px;
      font-size: 18px;
      line-height: 32px;
      font-style: italic;

      @media (max-width: 506px) {
        font-size: 14px;
      }

      @media (max-width: 360px) {
        font-size: 12px;
      }
    }
  }
`