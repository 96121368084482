import styled from 'styled-components'


export const StyledSection6Container = styled.div`
  padding-top: 30px;
  padding-bottom: 140px;
  
  @media(max-width: 450px){
    margin-bottom: 80px;
  }
    
  .textBlock{
    flex-direction: column;
    margin-top: 90px;
    margin-bottom: 60px;
    
    .title{
      font-size: 48px;
      line-height: 64px;
      text-align: center;
      font-weight: bold;
      
      @media(max-width: 991px){
        font-size: 40px;
      }

      @media(max-width: 450px){
        font-size: 32px;
        line-height: 40px;
      }
      
      @media(max-width: 400px){
        font-size: 24px;
      line-height: 32px;
      }
    }
    
    .subtitle{
      font-size: 18px;
      line-height: 28px;
      text-align: center;
    }

    @media(max-width: 768px){
      margin-bottom: 40px !important;
    }
  }
  
  .circle{
    position: absolute;
    top: 8px;
    left: -6px;
    background-color: #003399;
    width: 4px;
    height: 4px;
    border-radius: 15px;
  }
  
  .brandName{
    font-size: 14px;
    line-height: 20px;
    
    @media (min-width: 400px) and (max-width: 450px){
      font-size: 13px;
    }
  }
  
  .showMoreButton{
    margin-top: 60px;
    width: 100%;    

    .ant-btn {
      background-color: #fff !important;
      border: 1px solid rgba(0, 51, 153, 1);
      
      span{
        color: rgba(0, 51, 153, 1);
      }
    }
  }
  
`