import styled from "styled-components";

export const StyledDropDown = styled.div(({theme}) => ({
    maxHeight: 264,
    overflow: 'hidden',
    overflowY: 'auto',
    '.rc-virtual-list-holder': {
        maxHeight: 'none !important',
        overflow: 'visible !important',
    },
}))