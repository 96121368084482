import Icon from "@ant-design/icons";


const Svg = () => (
  <svg width="156" height="159" viewBox="0 0 156 159" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M146 0H10C4.47715 0 0 4.47715 0 10V149C0 154.523 4.47716 159 10 159H28C28 159 27.5 151 34.5 151H120C124.418 151 128 154.582 128 159H146C151.523 159 156 154.523 156 149V10C156 4.47715 151.523 0 146 0Z" fill="#C70039"/>
  </svg>
)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
