import Icon from "@ant-design/icons";


const Svg = () => (

    <svg width="145" height="48" viewBox="0 0 145 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_52_102)">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M145.001 22.503V31.6814C145.001 35.1563 142.036 37.998 138.414 37.998H104.24C100.616 37.998 97.6533 35.1543 97.6533 31.6814V28.0962L104.363 26.3727V32.7776H138.293V21.4088L106.095 21.4068C102.418 21.4469 99.3856 18.6333 99.3856 15.0902C99.3856 12.1663 99.3856 9.24048 99.3856 6.31663C99.3856 2.84168 102.351 0 105.972 0C116.735 0.12024 127.651 0.128257 138.414 0C142.038 0 145.001 2.84168 145.001 6.31663V10.0942H138.291V5.22244H106.093C106.093 8.87776 106.093 12.5331 106.093 16.1864H138.291C141.969 16.1463 145.001 18.9599 145.001 22.503Z"
                  fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M87.0928 0H94.283V38H87.0928V0Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M73.6133 0H83.6119C76.1981 6.33267 68.8742 12.6673 61.5482 19C68.8721 25.3327 76.1981 31.6673 83.6119 38H73.6133C67.8858 33.1082 62.2106 28.2144 56.5499 23.3226C50.8872 28.2144 45.214 33.1082 39.4865 38C35.9656 38 32.4426 38 28.9217 38V28.8517H13.1664L7.1902 38H0L24.8241 0.00200367C29.7115 0.00200367 34.599 0.00200367 39.4865 0.00200367C45.214 4.89379 50.8872 9.78757 56.5499 14.6794C62.2127 9.78757 67.8858 4.89379 73.6133 0.00200367V0ZM35.0504 33.2325C40.5752 28.489 46.0624 23.7435 51.5496 18.998C46.0624 14.2545 40.5752 9.50902 35.0504 4.76353L42.1633 18.998L35.0504 33.2325ZM28.9196 22.9719V4.73547L17.0049 22.9719H28.9196Z"
                  fill="white"/>
            <path
                d="M0 47.9957H0.959989L1.70571 46.52H5.64649L6.39222 47.9957H7.46564L4.22437 42.0022H3.18456L0 47.9957ZM2.11113 45.7551L3.6803 42.6892L5.24948 45.7551H2.11113ZM11.2069 47.9957H12.1774V45.6622H15.9039C16.7504 45.6622 17.1348 45.336 17.1348 44.4998V43.1646C17.1348 42.3284 16.7504 42.0022 15.9039 42.0022H11.2069V47.9957ZM15.5195 42.78C15.9606 42.78 16.1517 42.9074 16.1517 43.4065V44.2427C16.1517 44.7548 15.948 44.8693 15.5195 44.8693H12.1774V42.7778H15.5195V42.78ZM21.3739 47.9957H22.3444V45.6622H26.0709C26.9175 45.6622 27.3019 45.336 27.3019 44.4998V43.1646C27.3019 42.3284 26.9175 42.0022 26.0709 42.0022H21.3739V47.9957ZM25.6865 42.78C26.1276 42.78 26.3188 42.9074 26.3188 43.4065V44.2427C26.3188 44.7548 26.115 44.8693 25.6865 44.8693H22.3444V42.7778H25.6865V42.78ZM31.541 47.9957H36.927V47.1595H32.5115V42.0022H31.541V47.9957ZM40.7732 47.9957H41.7437V42.0022H40.7732V47.9957ZM45.4891 47.9957H46.4491L47.1949 46.52H51.1356L51.8814 47.9957H52.9548L49.7135 42.0022H48.6737L45.4891 47.9957ZM47.6003 45.7551L49.1694 42.6892L50.7386 45.7551H47.6003ZM56.696 47.9957H57.5888V44.4653C57.5888 44.0245 57.5783 43.5945 57.5216 43.1538C57.7127 43.4908 57.9396 43.6658 58.1097 43.8279L62.5925 47.9978H63.1344V42.0043H62.2417V45.2906C62.2417 45.7206 62.2417 46.2089 62.332 46.7663C62.0946 46.453 61.8699 46.1981 61.6661 46.0122L57.3073 42.0065H56.6981V48L56.696 47.9957ZM72.9507 42.8167V44.1757L73.9212 43.9899V43.4217C73.9212 42.3068 73.6166 42.0043 72.5663 42.0043H69.304C68.2537 42.0043 67.9386 42.3176 67.9386 43.4217V46.5805C67.9386 47.6845 68.2558 47.9978 69.304 47.9978H72.5663C73.6166 47.9978 73.9212 47.6954 73.9212 46.5805V45.8718L72.9507 45.6622V47.1595H68.9091V42.8167H72.9507ZM78.343 47.9957H84V47.1703H79.3135V45.2647H82.1472V44.4739H79.3135V42.8016H83.9538V42H78.343V47.9935V47.9957Z"
                fill="#FEFEFE"/>
            <path
                d="M90 48H90.9689V45.6649H92.6822L95.0498 48H96.4926L93.8314 45.6649H94.6891C95.5342 45.6649 95.918 45.3384 95.918 44.5016V43.1654C95.918 42.3286 95.5342 42.0022 94.6891 42.0022H90V48ZM94.3053 42.7805C94.7331 42.7805 94.9365 42.8973 94.9365 43.3968V44.2681C94.9365 44.7805 94.7331 44.8843 94.3053 44.8843H90.9689V42.7805H94.3053ZM100.884 48H106.531V47.1741H101.853V45.267H104.682V44.4757H101.853V42.8022H106.485V42H100.884V47.9978V48ZM111.137 48H112.105V45.6649H115.826C116.671 45.6649 117.055 45.3384 117.055 44.5016V43.1654C117.055 42.3286 116.671 42.0022 115.826 42.0022H111.137V48ZM115.442 42.7805C115.882 42.7805 116.073 42.9081 116.073 43.4076V44.2443C116.073 44.7568 115.87 44.8714 115.442 44.8714H112.105V42.7784H115.442V42.7805ZM119.596 48H120.555L121.299 46.5232H125.233L125.978 48H127.049L123.813 42.0022H122.775L119.596 48ZM121.704 45.7578L123.27 42.6897L124.837 45.7578H121.704ZM131.222 48H132.191V42.0022H131.222V48ZM137.507 48H138.476V45.6649H140.19L142.557 48H144L141.339 45.6649H142.196C143.042 45.6649 143.425 45.3384 143.425 44.5016V43.1654C143.425 42.3286 143.042 42.0022 142.196 42.0022H137.507V48ZM141.813 42.7805C142.241 42.7805 142.444 42.8973 142.444 43.3968V44.2681C142.444 44.7805 142.241 44.8843 141.813 44.8843H138.476V42.7805H141.813Z"
                fill="#FEFEFE"/>
        </g>
        <defs>
            <clipPath id="clip0_52_102">
                <rect width="145" height="48" fill="white"/>
            </clipPath>
        </defs>
    </svg>
)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
