import Icon from "@ant-design/icons";


const Svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="184" height="184" viewBox="0 0 184 184" fill="none">
            <path
                d="M92 184C41.1898 184 0 142.81 0 92C0 41.1898 41.1898 0 92 0C142.81 0 184 41.1898 184 92C184 142.81 142.81 184 92 184Z"
                fill="#CAE5FC"/>
            <path opacity="0.2" d="M51.6598 158.214L28.5521 144.897L13.9629 153.318L36.9727 166.634L51.6598 158.214Z"
                  fill="#00549D"/>
            <path opacity="0.2"
                  d="M63.5075 123.16C65.4658 122.181 67.522 121.299 69.5782 120.614L120.2 143.428L168.178 115.718L118.927 87.3232L73.201 115.033C67.522 114.739 61.745 114.348 56.066 114.054C53.4223 113.858 50.5828 113.76 48.0371 114.445C45.6871 115.131 43.6309 116.404 41.5747 117.677C37.5603 120.222 33.5458 123.649 31.4896 128.153C30.6084 129.916 30.5105 132.168 31.5875 133.832C32.175 134.812 33.1541 135.497 34.0354 136.084C36.1895 137.553 38.2457 139.12 40.3998 140.588C42.1622 141.861 43.9247 143.036 45.883 143.82C48.9183 144.995 52.2474 144.799 55.4785 144.701C59.0034 144.603 62.5283 144.407 65.9553 143.624C67.8157 143.232 69.6761 142.547 71.6343 142.253C73.9843 141.763 76.4321 141.763 78.7821 141.666C82.6986 141.568 86.6152 141.176 90.4338 140.784C91.413 140.686 92.49 140.393 92.7838 139.511C92.9796 138.63 92.1963 137.847 91.413 137.357C88.1818 135.497 84.1673 136.084 80.4466 136.084C73.5926 135.987 66.5428 132.853 62.6263 127.272C62.1367 126.489 61.6471 125.608 61.9409 124.726C62.0388 124.041 62.8221 123.551 63.5075 123.16Z"
                  fill="#00549D"/>
            <path
                d="M64.8776 78.6094L68.3046 81.0572C73.7878 81.0572 85.0479 81.7426 85.0479 81.7426V85.6592C77.1169 88.107 68.0109 88.2049 64.6818 87.03C63.8006 86.7362 62.7235 86.3446 61.6465 85.855C63.8006 84.3863 64.8776 81.5468 64.8776 78.6094Z"
                fill="#FFB294"/>
            <path
                d="M81.7199 86.3431L86.0281 86.9306L90.2384 87.2244L94.5466 86.0494C95.4279 85.7557 95.6237 85.1682 94.7425 85.2661L92.6863 85.4619L96.0154 84.287C97.4841 83.7974 97.2882 83.112 96.3091 83.112L95.1341 83.2099L97.0924 82.5245C98.2674 82.2308 98.0716 81.4474 97.1903 81.3495L91.6092 81.8391L96.7987 80.4683C97.6799 80.1746 97.4841 79.3912 96.5049 79.3912L88.5739 80.4683L87.1052 79.5871L89.3572 77.0413C89.9447 76.3559 89.4551 75.5726 88.6718 76.258C87.6927 77.1392 86.5177 77.9225 85.3427 78.51L82.5032 82.0349L79.9575 83.7974L78.6846 85.0703L81.7199 86.3431Z"
                fill="#E68660"/>
            <path d="M120.199 66.6625V140.196L76.334 114.934V41.3027L120.199 66.6625Z" fill="#779CBF"/>
            <path d="M76.334 41.303L120.199 66.6627L162.89 41.9884L119.024 16.7266L76.334 41.303Z" fill="#F0FAFF"/>
            <path d="M162.89 41.9893V115.523L120.2 140.197V66.6636L162.89 41.9893Z" fill="white"/>
            <path
                d="M150.816 99.5936C157.219 91.0797 158.522 81.2528 153.724 77.6447C148.927 74.0367 139.847 78.0136 133.444 86.5275C127.04 95.0415 125.738 104.868 130.535 108.476C135.332 112.084 144.412 108.108 150.816 99.5936Z"
                fill="white"/>
            <path
                d="M151.829 100.774C158.233 92.2603 159.535 82.4335 154.738 78.8254C149.941 75.2173 140.861 79.1943 134.457 87.7082C128.053 96.2221 126.751 106.049 131.548 109.657C136.346 113.265 145.426 109.288 151.829 100.774Z"
                fill="#8ABDF1"/>
            <path
                d="M132.243 100.542C132.243 101.619 132.439 102.696 132.732 103.479C134.103 107.102 138.215 107.983 143.111 105.144C149.084 101.717 153.98 93.9817 153.98 88.009C153.98 83.3091 151.042 81.0571 146.93 81.8404C145.755 82.0362 144.482 82.5258 143.111 83.3091C137.04 86.834 132.243 94.4713 132.243 100.542Z"
                fill="#182939"/>
            <path
                d="M132.243 100.542C132.243 101.619 132.439 102.697 132.732 103.48C134.495 104.753 137.334 104.655 140.565 102.794C145.951 99.6612 150.357 92.7093 150.357 87.324C150.357 84.3866 149.084 82.4283 147.028 81.8408C145.853 82.0366 144.58 82.5262 143.209 83.3095C137.04 86.8344 132.243 94.4717 132.243 100.542Z"
                fill="#051117"/>
            <path opacity="0.75"
                  d="M141.349 103.186C141.545 103.186 141.741 103.088 141.936 102.99C147.224 99.9547 151.63 93.0028 151.63 87.9113C151.63 87.2259 151.14 86.7363 150.455 86.7363C149.77 86.7363 149.28 87.2259 149.28 87.9113C149.28 92.1216 145.265 98.3881 140.761 100.934C140.174 101.228 139.978 102.011 140.37 102.598C140.468 102.99 140.859 103.186 141.349 103.186Z"
                  fill="white"/>
            <path opacity="0.2"
                  d="M136.649 97.7025C137.139 97.7025 137.629 97.4088 137.727 96.9192C138.608 94.6672 139.979 92.4151 141.741 90.5548C142.231 90.0652 142.133 89.2819 141.643 88.8902C141.153 88.4007 140.37 88.4986 139.979 88.9881C138.02 91.1422 136.454 93.688 135.474 96.1359C135.279 96.7234 135.572 97.4088 136.16 97.7025C136.356 97.6046 136.454 97.7025 136.649 97.7025Z"
                  fill="white"/>
            <path opacity="0.5"
                  d="M145.068 58.6344C145.558 58.3407 145.95 58.2428 146.341 58.1449C146.929 58.0469 147.418 58.1449 147.81 58.3407L147.908 58.4386C148.006 58.4386 148.006 58.5365 148.104 58.5365L149.377 59.5157L148.691 60.4948C148.593 62.4531 147.027 64.9009 145.068 66.0759C144.97 66.1738 144.873 66.1738 144.677 66.2717L143.796 67.5446L142.131 66.2717C141.935 66.0759 141.739 65.7822 141.543 65.4884C141.446 65.1947 141.348 64.9009 141.348 64.5093C141.446 62.3552 143.012 59.8094 145.068 58.6344Z"
                  fill="#5EA6E9"/>
            <path
                d="M143.012 65.5861C143.012 65.9778 143.11 66.2715 143.208 66.5653C143.698 67.8382 145.068 68.034 146.733 67.1528C148.789 65.9778 150.454 63.432 150.454 61.3758C150.454 59.8092 149.475 59.0259 148.104 59.3196C147.712 59.4176 147.223 59.6134 146.831 59.8092C144.579 60.9842 143.012 63.5299 143.012 65.5861Z"
                fill="#182939"/>
            <path
                d="M154.175 51.4864C154.371 51.3885 154.566 51.2906 154.762 51.2906C155.056 51.1926 155.252 51.2906 155.448 51.3885C155.546 51.3885 155.546 51.4864 155.546 51.4864L156.133 51.878L155.839 52.2697C155.741 53.1509 155.056 54.228 154.273 54.7175C154.175 54.7175 154.175 54.8155 154.077 54.8155L153.685 55.4029L152.902 54.8155C152.804 54.7176 152.706 54.6196 152.608 54.4238C152.608 54.3259 152.51 54.1301 152.51 54.0322C152.51 53.1509 153.196 51.976 154.175 51.4864Z"
                fill="white"/>
            <path
                d="M153.195 54.5215C153.195 54.7173 153.195 54.8152 153.293 54.9132C153.489 55.5006 154.077 55.5985 154.86 55.2069C155.741 54.7173 156.524 53.5424 156.524 52.6611C156.524 51.9757 156.133 51.5841 155.447 51.7799C155.252 51.7799 155.056 51.8778 154.86 51.9757C153.881 52.5632 153.195 53.6403 153.195 54.5215Z"
                fill="#779CBF"/>
            <path
                d="M158.679 48.9405C158.875 48.8426 159.07 48.7447 159.266 48.7447C159.56 48.6467 159.756 48.7447 159.952 48.8426C160.049 48.8426 160.05 48.9405 160.05 48.9405L160.637 49.3321L160.343 49.7238C160.245 50.605 159.56 51.6821 158.777 52.1717C158.679 52.1717 158.679 52.2696 158.581 52.2696L158.189 52.8571L157.406 52.2696C157.308 52.1716 157.21 52.0737 157.112 51.8779C157.112 51.78 157.014 51.5842 157.014 51.4863C157.112 50.605 157.797 49.4301 158.679 48.9405Z"
                fill="white"/>
            <path
                d="M157.797 51.9756C157.797 52.1714 157.797 52.2693 157.895 52.3673C158.091 52.9547 158.679 53.0527 159.462 52.661C160.343 52.1714 161.126 50.9965 161.126 50.1152C161.126 49.4298 160.735 49.0382 160.049 49.234C159.854 49.234 159.658 49.3319 159.462 49.4298C158.483 49.9194 157.797 51.0944 157.797 51.9756Z"
                fill="#779CBF"/>
            <path d="M138.411 61.2783L123.234 69.9927V79.9799L138.411 71.2655V61.2783Z" fill="#182939"/>
            <path opacity="0.5" d="M162.89 101.521V115.522L120.2 140.197V126.195L162.89 101.521Z" fill="#EDEDED"/>
            <path d="M125.193 71.7543L129.991 69.0127V71.4605L125.193 74.2021V71.7543Z" fill="white"/>
            <path opacity="0.5" d="M156.428 56.2832L153.687 57.9477V60.1018L156.428 58.4373V56.2832Z" fill="#5EA6E9"/>
            <path d="M157.407 55.6975V57.8516L160.148 56.285V54.1309L157.407 55.6975Z" fill="#182939"/>
            <path opacity="0.5" d="M156.428 59.7109L153.687 61.2776V63.4317L156.428 61.865V59.7109Z" fill="#5EA6E9"/>
            <path d="M157.407 59.1233V61.2774L160.148 59.7108V57.5566L157.407 59.1233Z" fill="#779CBF"/>
            <path opacity="0.5" d="M156.428 63.04L153.687 64.7046V66.8587L156.428 65.1942V63.04Z" fill="#5EA6E9"/>
            <path d="M157.407 62.551V64.7051L160.148 63.1385V60.9844L157.407 62.551Z" fill="#779CBF"/>
            <path d="M156.428 66.4678L153.687 68.1323V70.2864L156.428 68.6219V66.4678Z" fill="white"/>
            <path d="M157.407 65.8801V68.0342L160.148 66.4676V64.3135L157.407 65.8801Z" fill="white"/>
            <path
                d="M144.482 65.4876C144.482 65.7814 144.482 65.9772 144.58 66.173C144.874 67.0543 145.951 67.2501 147.126 66.5647C148.594 65.6835 149.769 63.921 149.769 62.4523C149.769 61.3753 149.084 60.7878 148.105 60.9836C147.811 61.0815 147.517 61.1794 147.224 61.3753C145.657 62.1586 144.482 64.0189 144.482 65.4876Z"
                fill="#CAE5FC"/>
            <path d="M117.655 69.405V134.616L78.8809 112.193V46.8848L117.655 69.405Z" fill="#182939"/>
            <path d="M114.717 73.0281V138.337L75.8447 120.908V55.6973L114.717 73.0281Z" fill="white"/>
            <path
                d="M47.3517 132.755C45.6872 134.615 45.5893 138.238 46.0789 140.001C48.1351 141.273 49.9954 141.763 51.4641 141.175C55.7724 141.763 58.0244 142.742 60.2764 142.546C62.7243 142.35 64.3888 141.959 66.0533 139.903C66.7387 139.021 66.445 136.965 64.5846 136.378C61.0597 135.594 58.0244 134.713 56.4578 133.538L54.4995 127.565L48.233 130.797L47.3517 132.755Z"
                fill="black"/>
            <path
                d="M44.3164 122.671L48.135 126.391C48.135 126.391 51.7578 127.762 49.7996 130.014C48.8204 131.091 46.0788 131.385 46.0788 131.385C43.4351 131.287 44.2185 131.483 38.8332 127.175L35.4062 126C33.5458 125.314 32.2729 124.139 32.2729 122.083L32.5667 120.712L35.5041 115.719L38.1478 115.817L44.3164 117.579V122.671Z"
                fill="black"/>
            <path
                d="M36.1895 117.677L42.6518 119.145C45.5892 118.852 48.2329 118.362 50.5828 117.873C53.0307 117.285 55.1848 116.698 56.9472 116.012C68.1094 111.704 68.0115 105.242 68.0115 105.242C66.347 99.6605 49.8974 98.2897 44.0226 97.996C41.4768 97.8001 39.8123 97.8001 39.8123 97.8001C40.2039 97.5064 36.2874 117.187 36.1895 117.677Z"
                fill="#0C2136"/>
            <path
                d="M64.5845 123.356L55.8701 133.735C55.8701 133.735 51.5619 135.987 47.1558 132.853C47.4495 129.72 49.0161 126.293 50.8765 123.552C52.7369 120.908 54.891 118.754 56.4576 118.068C56.6534 117.97 56.7513 117.971 56.7513 117.971C56.6534 117.971 50.9744 120.32 44.61 121.593C41.5747 122.181 38.3435 122.475 35.4061 122.181C29.1396 121.398 27.9646 113.858 27.8667 108.669C27.8667 105.731 28.1604 103.577 28.1604 103.577L37.8539 105.046L39.6164 105.34C39.6164 105.34 40.1059 105.34 40.8893 105.438C41.3788 105.438 41.8684 105.535 42.5538 105.633C43.9246 105.731 45.5891 105.927 47.5474 106.221C51.2682 106.808 55.5764 107.69 59.395 109.158C62.2345 110.235 64.7803 111.704 66.4448 113.564C67.0323 114.25 67.6198 115.033 67.9135 115.816C67.9135 115.816 68.6969 119.733 64.5845 123.356Z"
                fill="#214B71"/>
            <path
                d="M59.6887 74.6924L64.976 78.511C64.976 81.5464 63.899 84.4838 61.6469 86.0504C59.1012 84.9734 56.1638 83.6026 53.1284 82.0359L59.6887 74.6924Z"
                fill="white"/>
            <path
                d="M55.5762 91.8275C55.1845 92.5129 54.597 93.1983 54.0095 93.7858L53.6179 94.9607L52.6387 96.3315L49.2118 103.283L47.4493 106.221C47.3514 106.319 46.9597 106.515 46.2743 106.906C43.8265 108.179 38.1475 110.921 32.958 110.529C31.0977 110.431 29.3352 109.844 27.7686 108.767C27.6707 107.592 26.4957 95.3524 31.9789 86.9318C37.6579 78.1195 43.0432 67.8386 56.3595 73.028C56.3595 73.028 56.5553 73.7134 56.8491 74.7904C57.9261 78.3154 59.0032 86.2464 55.5762 91.8275Z"
                fill="#214B71"/>
            <path
                d="M40.106 86.932C40.106 86.932 45.4912 81.5467 50.8765 83.8966C54.9889 85.757 55.8701 90.2611 56.0659 92.3173C59.4929 86.7362 57.9263 78.2176 56.9472 74.5948C53.4223 71.9511 48.4286 72.147 47.0579 73.0282C42.9455 76.4552 40.7914 81.6446 40.106 86.932Z"
                fill="white"/>
            <path
                d="M54.0095 95.1559L49.3096 103.087C43.9244 103.674 40.0078 104.262 38.3433 100.052C38.3433 100.052 38.3433 100.052 39.0287 94.2747C39.7141 88.4977 44.5118 83.9937 50.0929 86.0499C53.7158 87.4207 54.1074 92.1205 54.0095 95.1559Z"
                fill="white"/>
            <path
                d="M63.9971 78.6092C63.8992 78.8051 58.0243 85.9528 58.0243 85.9528C58.0243 85.9528 53.4224 82.2321 53.7161 75.2802C54.8911 73.8114 55.8702 73.0281 57.9264 70.874L63.9971 78.6092Z"
                fill="#FFB294"/>
            <path
                d="M56.1636 71.9515C56.1636 71.9515 55.2824 64.3142 62.528 62.1601C62.528 62.1601 71.0466 61.083 72.809 68.3287C72.809 68.3287 73.3965 73.0285 71.2424 75.0847L71.3403 76.7493C71.3403 77.1409 71.1445 77.5326 70.7528 77.7284L69.9695 78.1201L68.5987 80.3721C68.1091 81.2533 67.13 81.8408 66.1509 81.8408C65.1717 81.9387 63.8988 81.7429 62.528 80.8617C59.2969 79.0013 56.1636 71.9515 56.1636 71.9515Z"
                fill="#FFB294"/>
            <path
                d="M72.7111 71.5589C71.1444 73.4192 66.2487 72.1463 64.78 75.2796C64.78 75.2796 62.3322 78.5107 59.101 76.4546C55.7719 74.3984 55.5761 72.8317 55.5761 72.8317C55.5761 72.8317 55.0865 64.9007 59.6885 62.6487C63.8988 60.5925 67.2279 61.6695 69.8715 63.432C72.5152 65.0965 75.061 68.7193 72.7111 71.5589Z"
                fill="black"/>
            <path
                d="M63.9964 73.8121C63.7026 73.8121 63.4089 74.0079 63.1151 74.2038C62.4297 74.7913 62.0381 75.6725 62.0381 76.5537C62.0381 76.9454 62.2339 77.4349 62.6256 77.6308C62.9193 77.8266 63.2131 77.7287 63.5068 77.6308C63.8005 77.5328 63.9964 77.2391 64.1922 77.0433C64.5839 76.5537 65.1713 75.7704 65.1713 75.085C65.2693 74.3996 64.7797 73.7142 63.9964 73.8121Z"
                fill="#FFB294"/>
            <path opacity="0.5"
                  d="M66.4446 113.466L63.703 113.858L52.6387 115.424L35.3079 110.822L33.1538 110.235C35.21 109.06 36.7766 107.102 38.0495 104.85C40.7911 99.7582 41.6723 93.6875 41.6723 93.6875L46.1764 98.2895L53.5199 96.2333L49.4075 103.185L47.6451 106.123C51.2679 106.71 55.674 107.591 59.4927 109.158C62.2343 110.235 64.78 111.606 66.4446 113.466Z"
                  fill="#214B71"/>
            <path
                d="M51.562 104.752C54.7932 104.066 57.7306 101.717 58.7097 98.4854L63.8992 107.396L82.0133 106.612L82.307 111.41C82.307 111.41 71.0469 115.522 64.6825 114.445C64.6825 114.445 61.1576 114.543 55.2827 108.375L51.562 104.752Z"
                fill="#FFB294"/>
            <path
                d="M51.6597 105.241L44.1204 97.9955C36.581 90.554 47.2536 81.6438 53.2264 89.7707L59.5908 99.1705C58.6116 102.597 54.9888 104.458 51.6597 105.241Z"
                fill="white"/>
            <path
                d="M79.5651 111.313L83.7754 112.292L87.9857 112.977L92.3919 112.194C93.371 111.9 93.5668 111.411 92.6856 111.411L90.6294 111.508L94.0564 110.627C95.5251 110.236 95.4272 109.648 94.4481 109.55H93.2731L95.2314 109.061C96.4063 108.865 96.3084 108.081 95.4272 107.984H89.8461L95.1334 107.102C96.1126 106.907 95.9168 106.123 94.9376 106.025L86.9087 106.417L85.5379 105.34L87.9857 102.99C88.5732 102.402 88.1816 101.521 87.3003 102.207C86.2233 102.99 85.0483 103.675 83.7754 104.165L81.9151 106.711L77.9006 108.963L76.5298 110.138L79.5651 111.313Z"
                fill="#FFB294"/>
            <path
                d="M89.064 149.792L88.6724 148.715L88.5745 148.618C88.4765 148.618 88.3786 148.52 88.2807 148.618L87.0078 148.813C86.9099 148.813 86.9099 148.813 86.9099 148.813L87.1058 149.401V150.086C87.1058 150.184 86.9099 150.282 86.812 150.282C86.5183 150.282 86.1266 150.184 85.9308 149.988C85.3433 149.695 84.9516 149.205 84.8537 148.715V148.324C84.7558 148.226 84.6579 148.128 84.56 148.03L74.7686 143.134C74.5728 143.036 74.1811 142.939 73.8874 143.036C73.202 143.134 72.5166 143.036 71.9291 142.743C71.8312 142.645 71.7332 142.645 71.6353 142.547C71.3416 142.351 71.1458 141.959 71.0479 141.568V140.882C71.0479 140.491 71.2437 140.001 71.7332 139.707C71.8312 139.609 72.027 139.609 72.1249 139.707C72.1249 139.707 72.2228 139.707 72.2228 139.805L72.8103 140.882L72.9082 140.98C73.0061 140.98 73.104 141.078 73.2999 140.98L74.5728 140.686L74.279 140.099V139.414C74.279 139.316 74.3769 139.218 74.5728 139.218C74.9644 139.316 75.2582 139.414 75.5519 139.512C76.1394 139.805 76.531 140.295 76.531 140.784V141.274C76.6289 141.372 76.7269 141.47 76.8248 141.568L86.5183 146.463C86.7141 146.561 87.1058 146.659 87.3995 146.561C87.8891 146.561 88.3786 146.561 88.8682 146.659C89.064 146.757 89.2599 146.757 89.4557 146.855C90.0432 147.149 90.239 147.54 90.3369 148.03V148.715C90.3369 149.205 90.0432 149.695 89.5536 149.988C89.3578 149.792 89.1619 149.792 89.064 149.792Z"
                fill="#4D4D4D"/>
            <path
                d="M85.8318 149.303C86.1256 149.401 86.4193 149.499 86.713 149.597C86.9089 149.597 87.1047 149.499 87.0068 149.401L86.6151 148.324C86.6151 148.226 86.713 148.128 86.9089 148.03L88.1817 147.834C88.2797 147.834 88.3776 147.834 88.4755 147.834C88.5734 147.834 88.5734 147.932 88.5734 147.932L88.9651 149.009C88.9651 149.009 88.9651 149.107 89.063 149.107C89.1609 149.107 89.3567 149.205 89.4546 149.107C90.4338 148.422 90.5317 147.345 89.3567 146.757C89.1609 146.66 89.063 146.562 88.7692 146.562C88.2797 146.464 87.7901 146.366 87.3005 146.464C87.0068 146.464 86.6151 146.464 86.4193 146.366L76.7258 141.47C76.4321 141.372 76.3342 141.176 76.3342 140.883C76.4321 140.295 76.0404 139.806 75.355 139.414C75.0613 139.218 74.6696 139.12 74.3759 139.12C74.18 139.12 73.9842 139.218 74.0821 139.316L74.6696 140.295C74.7675 140.393 74.6696 140.491 74.4738 140.589L73.2009 140.883C73.103 140.883 72.9072 140.883 72.8092 140.883L72.7113 140.785L72.1238 139.708C72.1238 139.708 72.1238 139.708 72.0259 139.61C71.928 139.61 71.7322 139.512 71.6343 139.61C70.6551 140.197 70.7531 141.274 71.4385 141.862C71.5364 141.96 71.6343 141.96 71.7322 142.058C72.2218 142.351 73.0051 142.449 73.6905 142.351C73.9842 142.351 74.3759 142.351 74.5717 142.449L84.3631 147.345C84.6568 147.443 84.7548 147.639 84.7548 147.834C84.7548 148.324 85.1464 148.912 85.8318 149.303Z"
                fill="#CCCCCC"/>
            <path
                d="M94.8419 146.954C94.8419 146.562 95.1357 145.975 95.5273 145.779L96.1148 145.387C96.3106 145.289 96.5065 145.289 96.6044 145.387L79.8611 135.694C79.7632 135.596 79.5673 135.596 79.3715 135.694L78.784 136.085C78.3924 136.281 78.0986 136.771 78.0986 137.26C78.0986 137.456 78.1965 137.652 78.2945 137.652L95.0377 147.345C94.9398 147.345 94.8419 147.149 94.8419 146.954Z"
                fill="#BD8B55"/>
            <path
                d="M88.6731 143.429C88.6731 142.94 88.9669 142.45 89.3585 142.156L90.0439 141.765C90.2398 141.667 90.4356 141.765 90.5335 141.863C90.5335 141.765 90.5335 141.765 90.4356 141.667L79.665 135.4C79.5671 135.302 79.3713 135.302 79.1755 135.498L78.4901 135.89C78.0984 136.086 77.8047 136.673 77.8047 137.163C77.8047 137.359 77.9026 137.554 78.0005 137.652L88.6731 143.821C88.771 143.919 88.9669 143.821 88.9669 143.821C88.869 143.723 88.9669 143.821 88.869 143.723C88.5752 143.625 88.6731 143.527 88.6731 143.429Z"
                fill="#E34248"/>
            <path d="M95.917 150.674L93.5671 150.576L92.1963 149.793V147.345L93.5671 148.128L95.917 148.226V150.674Z"
                  fill="#4F4F4F"/>
            <path d="M95.9165 148.227L101.596 144.996V147.444L95.9165 150.675V148.227Z" fill="#585858"/>
            <path d="M93.5671 148.128L92.1963 147.344L98.9524 143.428L101.596 144.994L95.917 148.226L93.5671 148.128Z"
                  fill="#373737"/>
            <path
                d="M98.2689 148.911C97.8772 149.107 97.5835 148.911 97.5835 148.519C97.5835 148.128 97.8772 147.54 98.2689 147.344L98.8564 146.953C99.248 146.757 99.5418 146.953 99.5418 147.344C99.5418 147.736 99.248 148.324 98.8564 148.519L98.2689 148.911Z"
                fill="#BD8B55"/>
            <path d="M37.7561 164.772L16.4109 152.337L14.6484 145.875L35.9937 158.31L37.7561 164.772Z" fill="#A32126"/>
            <path d="M35.9932 158.311L14.6479 145.876L28.5517 137.847L49.897 150.282L35.9932 158.311Z" fill="#C93B40"/>
            <path d="M49.8965 150.282L48.2319 158.703L37.7551 164.774L35.9927 158.311L49.8965 150.282Z" fill="#C1272D"/>
            <path d="M35.9937 158.31L14.6484 145.875L16.313 137.454L37.6582 149.889L35.9937 158.31Z" fill="#C1272D"/>
            <path d="M37.6568 149.89L16.3115 137.455L26.7883 131.482L48.2315 143.82L37.6568 149.89Z" fill="#FF3B42"/>
            <path d="M48.2319 143.819L49.8965 150.282L35.9927 158.311L37.6572 149.89L48.2319 143.819Z" fill="#FF3B42"/>
            <path d="M27.574 155.079L24.1471 153.121L23.5596 151.064L26.9866 153.023L27.574 155.079Z" fill="#999999"/>
            <path d="M26.9866 153.023L23.5596 151.065L24.1471 148.323L27.574 150.282L26.9866 153.023Z" fill="white"/>
            <path
                d="M36.2862 136.867L31.3905 134.027C30.8031 133.734 30.3135 133.734 30.0197 133.929L29.0406 134.517C28.649 134.713 28.4531 135.202 28.4531 135.79V138.14L29.726 138.923L30.7051 138.336V136.377L35.3071 139.021C35.6988 139.217 35.9925 139.706 35.9925 140.196V142.546L37.2654 143.329L38.2445 142.742V140.392C38.2445 139.021 37.3633 137.454 36.2862 136.867Z"
                fill="#4D4D4D"/>
            <path
                d="M35.3071 137.455C36.3842 138.042 37.2654 139.609 37.2654 140.882V143.231L35.9925 142.448V140.098C35.9925 139.707 35.6988 139.119 35.3071 138.923L30.4114 136.084C30.0197 135.888 29.726 136.084 29.726 136.475V138.825L28.4531 138.042V135.692C28.4531 134.419 29.3344 133.93 30.4114 134.615L35.3071 137.455Z"
                fill="#192936"/>
            <path
                d="M83.777 130.602L92.6871 135.694C92.6871 135.694 92.7851 135.791 92.7851 135.889C92.7851 136.085 92.6871 136.379 92.4913 136.477C92.3934 136.575 92.2955 136.575 92.2955 136.477L83.3853 131.385L82.2103 130.994L81.3291 130.112L81.8187 129.329L82.9937 129.721L83.777 130.602Z"
                fill="#999999"/>
            <path
                d="M98.8567 138.044L92.7861 135.009C92.6882 134.911 92.4923 134.911 92.1986 135.107C91.8069 135.302 91.5132 135.89 91.5132 136.379C91.5132 136.575 91.6111 136.771 91.709 136.869L97.388 140.59C97.1922 140.492 97.0943 140.198 97.0943 139.904C97.0943 139.219 97.5839 138.436 98.1713 138.044C98.3672 137.946 98.6609 137.946 98.8567 138.044Z"
                fill="#FF3B42"/>
            <path
                d="M99.1507 138.729C99.1507 138.043 98.6611 137.75 98.0736 138.141C97.4862 138.435 96.9966 139.316 96.9966 140.002C96.9966 140.687 97.4862 140.981 98.0736 140.589C98.6611 140.197 99.1507 139.414 99.1507 138.729Z"
                fill="#A32126"/>
            <path d="M81.23 130.112L82.307 130.896L82.7966 129.917L81.7196 129.329L81.23 130.112Z" fill="#E6E6E6"/>
            <path
                d="M82.8951 131.091L82.3076 130.895L82.8951 129.916L83.3847 130.308C83.5805 130.503 83.5805 130.699 83.4826 130.895C83.3847 131.091 83.0909 131.189 82.8951 131.091Z"
                fill="#B3B3B3"/>
    </svg>

)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
