import React from "react";
import {StyledTermsAndConditions} from "./index.styles";
import {Col, Divider, Row} from "antd";
import {InfoIcon} from "../../assets";
import {MyBackButton} from "../../components/common/MyBackButton/MyBackButton";
import {MyButton} from "../../components/common/MyButton/MyButton";
import {useNavigate} from "react-router-dom";

export const TermsAndConditions = () => {

  const navigate = useNavigate();

  const termsData = [
    {
      title: "Introduction",
      text: "These terms and conditions (the \"Terms and Conditions\") govern the use of axisrepair.com (the\"Site\"). This Site is owned and operated by Axis Appliance Repair Inc. This Site is a appliance repair shop advertising.",
      extraText: "By using this Site, you indicate that you have read and understand these Terms and Conditions and agree to abide by them at all times."
    },
    {
      title: "Intellectual Property",
      text: "All content published and made available on our Site is the property of Axis Appliance Repair Inc and the Site's creators. This includes, but is not limited to images, text, logos, documents, downloadable files and anything that contributes to the composition of our Site."
    },
    {
      title: "Limitation of Liability",
      text: "Axis Appliance Repair Inc and our directors, officers, agents, employees, subsidiaries, and affiliates will not be liable for any actions, claims, losses, damages, liabilities and expenses including legal fees from your use of the Site.",
    },
    {
      title: "Indemnity",
      text: "Except where prohibited by law, by using this Site you indemnify and hold harmless Axis ApplianceRepair Inc and our directors, officers, agents, employees, subsidiaries, and affiliates from any actions,claims, losses, damages, liabilities and expenses including legal fees arising out of your use of our Site or your violation of these Terms and Conditions.",
    },
    {
      title: "Applicable Law",
      text: "These Terms and Conditions are governed by the laws of the State of California.",
    },
    {
      title: "Severability",
      text: "If at any time any of the provisions set forth in these Terms and Conditions are found to be inconsistent or invalid under applicable laws, those provisions will be deemed void and will be removed from these Terms and Conditions. All other provisions will not be affected by the removal and the rest of these Terms and Conditions will still be considered valid.",
    },
    {
      title: "Changes",
      text: "These Terms and Conditions may be amended from time to time in order to maintain compliance withPage 1 of 2©2002-2024 LawDepot.com®the law and to reflect any changes to the way we operate our Site and the way we expect users to behave on our Site. We will notify users by email of changes to these Terms and Conditions or post a notice on our Site.",
    },
    // {
    //   title: "Contact Details",
    //   text: `Please contact us if you have any questions or concerns. Our contact details are as follows: \n (760) 439-7522 \n service@axisrepair.com \n 3815 Mission ave #204 Oceanside Ca 92057 \n You can also contact us through the feedback form available on our Site.`,
    // },

  ]


  return (
    <StyledTermsAndConditions>
      <Col xs={{
        pull: 1,
        span: 22,
        push: 1,
      }} lg={{
        pull: 3,
        span: 18,
        push: 3,
      }} xl={{
        pull: 4,
        span: 16,
        push: 4,
      }}>
        <div>
          <Row className="h1-row" justify="center" align="middle" style={{position: "relative"}}>
            <h1 className="h1-text" style={{textAlign: "center", marginRight: "auto", marginLeft: "auto"}}>Terms And
              Conditions</h1>
            <h1 className="h1-text-adaptive"
                style={{textAlign: "center", marginRight: "auto", marginLeft: "auto"}}>Terms And <br/> Conditions</h1>
          </Row>
          {termsData.map((termsItem, index) => (
              <div key={index + "-termItem" + index}>
                <Col className="termsTitleContainer">
                  <span className="termsTitle">{termsItem.title}</span>
                </Col>
                <Col>
                  <span className="termsText">{termsItem.text}</span>
                </Col>
                {termsItem.extraText ? (
                    <Col className="extraText">
                      <span className="termsText">{termsItem.extraText}</span>
                    </Col>
                ) : null}
                {index !== 7 ? <Divider/> : null}

              </div>
          ))}
          <div>
            <Col className="termsTitleContainer">
              <span className="termsTitle">Contact Details</span>
            </Col>
            <Col>
              <span className="termsText">Please contact us if you have any questions or concerns. Our contact details are as follows: <br/>
 (760) 439-7522 <br/>
 service@axisrepair.com <br/>
 3815 Mission ave #204 Oceanside Ca 92057 <br/>
 You can also contact us through the feedback form available on our Site.</span>
            </Col>

          </div>
          <Row className="infoContainer">
            <InfoIcon/>
            <span className="infoText">Effective Date: 1st day of January, 2024</span>
          </Row>
        </div>
      </Col>


    </StyledTermsAndConditions>
  )
}