import React from "react";
import {Col, Row, Steps, Grid} from "antd";
import {StyledSection5Container} from "./Section5.styles";
import {
    Badge, BookStepIcon, ListStepIcon, WrenchStepIcon, MedalStepIcon
} from "../../../../assets";
import {MyButton} from "../../../common/MyButton/MyButton";

const {useBreakpoint} = Grid;

export const Section5 = ({setIsModalOpen}) => {

    const screens = useBreakpoint();

    const stepsItem = [
        {
            icon: <BookStepIcon />,
            title: "Book Online Or With Phone",
            description: "Tell us about your \n appliance repair needs."
        },
        {
            icon: <ListStepIcon />,
            title: "We Identify the Problem",
            description: "Schedule your convenient \n appointment time."
        },
        {
            icon: <WrenchStepIcon/>,
            title: "Our Specialist Arrives",
            description: "Technician performs diagnostic and \n provides repair recommendation."
        },
        {
            icon: <MedalStepIcon/>,
            title: "We Solve Your Problem",
            description: "The job is done and backed by our \n workmanship promise."
        },

    ]

    const adaptiveSteps = [
      [
          {
              icon: <BookStepIcon />,
              title: "Book online or with phone",
              description: "Tell us about your \n appliance repair needs."
          },
          {
              icon: <ListStepIcon />,
              title: "We Identify the Problem",
              description: "Schedule your convenient \n appointment time."
          },
      ],
      [
          {
              icon: <WrenchStepIcon/>,
              title: "Our Specialist Arrives",
              description: "Technician performs diagnostic and \n provides repair recommendation."
          },
          {
              icon: <MedalStepIcon/>,
              title: "We Solve Your Problem",
              description: "The job is done and backed by our \n workmanship promise."
          },
      ]
    ]

    return (
        <StyledSection5Container>
            <Col xs= {{
                pull: 1,
                span: 22,
                push: 1,
            }}lg={{
                pull: 3,
                span: 18,
                push: 3,
            }} xl={{
                pull: 4,
                span: 16,
                push: 4,
            }}>
                <Row justify="center" style={{position: "relative"}}>
                    <Badge style={{position: "absolute", top: -127}}/>
                </Row>
                <Row className="textBlock" style={{flexDirection: "column", paddingTop: 66, marginBottom: 60}} align="middle">
                    <h1 className="title">Is your appliance broken? <br /> Let us help you!</h1>
                    <span className="subtitle">Your satisfaction is our guarantee. Don’t wait – if you need an appliance fixed, call us now! We're {!screens.lg ? null : <br/>} available 7 days a week to answer your questions and address household appliance issues. </span>
                </Row>

                {!screens.xl ? <div>
                    {adaptiveSteps.map((arrAdaptiveItems, index) => (
                        <div style={{position: "relative"}} key={index + "-firstArr"}>
                            <Row key={index + "-arrAdaptiveItem"} className="adaptiveContainer" justify="space-between">
                                {arrAdaptiveItems.map((adaptiveItem, indexItem) => (
                                    <Col span={10} key={`adaptive-item-${index}-${indexItem}`}>
                                        <Row align="middle" justify="center" className="adaptiveItemContainer"
                                             style={{flexDirection: "column", width: "100%"}}>
                                            <Col className="adaptive-icon"><span>{adaptiveItem.icon}</span></Col>
                                            <Col className="adaptive-title"><span>{adaptiveItem.title}</span></Col>
                                            <Col
                                                className="adaptive-description"><span>{adaptiveItem.description}</span></Col>
                                        </Row>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    ))}
                </div> : <Steps current={5} items={stepsItem} labelPlacement="vertical"/>}
                <Row justify="center" style={{paddingTop: 60, paddingBottom: 60}}>
                    <MyButton type="primary" onClick={() => setIsModalOpen(true)}>Schedule Appointment</MyButton>
                </Row>
            </Col>
        </StyledSection5Container>
    )
}