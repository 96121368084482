import Icon from "@ant-design/icons";


const Svg = () => (
  <svg width="134" height="55" viewBox="0 0 134 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4158_467)">
          <path fillRule="evenodd" clipRule="evenodd" d="M71.8016 34.9307H78.6135V18.6139C78.9868 18.8638 83.2148 24.4003 83.4561 24.702L85.4712 27.281C85.6321 27.4872 85.7226 27.6264 85.8823 27.8204C87.4725 29.7527 89.1168 32.0389 90.6777 33.9556C90.9416 34.2797 91.3646 34.9307 91.6036 34.9307H98.4947V8.00001H91.4452V24.3168C91.2291 24.1721 86.1575 17.63 85.8205 17.1889L78.8109 8.19861C78.6962 8.06418 78.7562 8 78.5343 8H71.8016C71.4193 8 71.5639 8.69725 71.5639 9.42575V33.5842C71.5639 33.8723 71.5562 34.1717 71.5661 34.4585C71.5852 35.0124 71.6121 34.9307 71.8016 34.9307L71.8016 34.9307Z" fill="black"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M14.1386 13.9406H24.6733C24.4393 14.3827 24.1969 14.8087 23.9134 15.24L13.5797 32.2332C13.3197 32.6676 13.0616 33.1277 12.7766 33.5686C12.5884 33.8598 12.0714 34.6244 12 34.9306H35.0495V29.1485C35.0495 28.7618 34.2315 28.9106 33.8615 28.9109C32.1625 28.9121 24.195 28.8015 23.8021 28.99C23.8773 28.7082 24.6619 27.5468 24.9378 27.0367C25.6044 25.8043 27.727 22.4332 28.5202 21.1142C29.0675 20.2042 29.5586 19.4077 30.1043 18.5003C31.1766 16.7174 32.1914 15.0422 33.2621 13.2619L35.619 9.36142C35.7941 9.07131 36.3255 8.26451 36.3961 7.99994H14.1386V13.9405L14.1386 13.9406Z" fill="black"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M103.168 34.9307H118.534V29.1486C118.534 28.966 118.479 28.9109 118.297 28.9109H110.217V24.3961H118.059V18.3762H110.217V13.9406H118.534V8H103.168V34.9307H103.168Z" fill="black"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M39.7227 34.9307H54.9306C55.1131 34.9307 55.1682 34.8756 55.1682 34.693V29.1486C55.1682 28.7542 54.0333 28.9109 53.2672 28.9109C52.106 28.9109 47.1749 28.7969 46.7721 28.9901V8H39.7227V34.9307L39.7227 34.9307Z" fill="black"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M59.8418 34.9307H66.8914V8H59.8418V34.9307Z" fill="black"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M106.812 43.0099V41.1089C107.273 41.1089 107.856 41.0517 108.209 41.2963C108.751 41.6719 108.712 42.8396 107.947 43.1148C107.916 43.1257 106.812 43.4486 106.812 43.0099ZM104.911 47.604H106.812V44.594L107.874 46.0671C108.057 46.3432 108.197 46.5716 108.384 46.824C108.557 47.0564 108.793 47.3092 108.872 47.604H111.248C110.891 47.0716 108.957 44.6765 108.872 44.3564C109.271 44.1649 109.742 43.9933 110.03 43.6142C111.183 42.0979 110.329 39.6039 108.079 39.6039H104.911V47.604Z" fill="black"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M96.5938 44.2773C96.5938 45.8724 97.2017 47.0374 98.5265 47.5723C100.67 48.4377 103.406 47.2555 103.406 44.9109V39.6039H101.505V44.594C101.505 46.2969 99.3329 46.3656 98.782 45.4158C98.3046 44.5926 98.4948 40.8604 98.4948 39.6039H96.5938V44.2773V44.2773Z" fill="black"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M80.1192 44.5149C80.1192 45.6796 80.4507 46.3133 81.0491 46.9116C82.0638 47.9264 83.6394 48.0305 84.9027 47.6351C85.3558 47.4933 85.6295 47.285 85.9223 46.9913C86.562 46.35 86.931 45.7269 86.931 44.6733V39.6039H85.03V44.4357C85.03 46.3059 83.0761 46.3005 82.4308 45.6091C82.223 45.3864 82.0201 44.923 82.0201 44.5149V39.604H80.1191V44.5149L80.1192 44.5149Z" fill="black"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M87.8018 47.604H90.0988C90.2484 47.3212 90.3289 47.176 90.5108 46.907C90.7394 46.5689 91.6337 45.0631 91.8414 44.9109C91.9146 45.185 93.2937 47.205 93.5047 47.604H95.8018C95.5965 47.216 95.3504 46.9299 95.1047 46.5586C94.8476 46.17 94.6228 45.8667 94.3612 45.4803L92.8711 43.4059C93.0615 43.1216 95.2611 39.8492 95.3266 39.6039H93.0295C92.9928 39.7617 92.0166 41.5291 91.7622 41.8218C91.7065 41.6133 91.5732 41.4374 91.46 41.2527C91.3388 41.055 91.2634 40.8967 91.1438 40.6977C90.914 40.3155 90.7321 39.9582 90.4949 39.604H88.277C88.3922 40.0353 90.1289 42.444 90.5196 43.0722C90.7767 43.4859 90.4343 43.8068 89.8277 44.6399C89.5723 44.9909 89.3839 45.2914 89.1481 45.6235L87.8018 47.604H87.8018Z" fill="black"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M113.703 43.9604V47.604H115.604V44.1188C115.604 43.8027 116.082 43.2036 116.254 42.9465L117.727 40.7763C117.913 40.4956 118.379 39.8901 118.456 39.6039H116.238C115.971 40.0019 115.737 40.3955 115.461 40.8079L114.882 41.7333C114.776 41.8888 114.786 41.9238 114.574 41.9802C114.443 41.4897 113.272 40.0655 113.149 39.604H110.852L113.006 42.8357C113.125 43.0139 113.703 43.812 113.703 43.9604L113.703 43.9604Z" fill="black"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M39.4854 47.604H40.5151V41.5842L42.7981 43.9745C43.0477 44.2604 43.3099 44.4979 43.5844 44.7723L46.6933 48V39.6039H45.7428V45.703C45.5726 45.5783 45.5427 45.4695 45.3678 45.2859L42.2458 42.0712C41.7032 41.391 41.2253 41.0329 40.6923 40.4565C40.4337 40.177 39.9227 39.4039 39.4854 39.2872V47.604Z" fill="black"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M58.1777 46.7327H56.9105V43.9604C58.0068 43.9604 59.7784 43.747 59.8142 45.3167C59.8347 46.2128 59.0916 46.7327 58.1777 46.7327ZM57.6233 43.0892H56.9105V40.4753C57.5246 40.4753 58.1636 40.4322 58.6593 40.7859C59.201 41.1725 59.3031 42.2709 58.7147 42.7548C58.4621 42.9624 58.0652 43.0891 57.6233 43.0891V43.0892ZM55.96 47.604C57.7367 47.604 60.3112 48.07 60.7169 45.7845C60.8268 45.165 60.7196 44.7156 60.4518 44.221C60.167 43.6951 59.8597 43.5881 59.3659 43.3268C59.9647 42.4326 59.9996 42.8778 59.9996 41.4258C59.9996 40.3792 58.9412 39.6039 57.861 39.6039H55.96V47.604H55.96Z" fill="black"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M74.7324 47.604H79.0889V46.0198H76.6334V39.6039H74.7324V47.604Z" fill="black"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M15.8019 41.4257C15.85 42.0043 16.9456 43.9808 16.99 44.5149H14.4554C14.5608 44.0623 15.171 42.7497 15.4171 42.1499C15.5077 41.9288 15.6065 41.5689 15.8019 41.4257ZM12.0791 47.604H13.1088L13.8422 45.8819C13.9364 45.6495 13.9483 45.3861 14.2177 45.3861H17.2276C17.4869 45.3861 17.491 45.5958 17.5811 45.8248C17.6759 46.0662 17.7412 46.1916 17.8388 46.4384C17.9907 46.8227 18.1619 47.2739 18.3365 47.604H19.3662L15.8019 39.2079C15.5523 39.3908 13.7506 43.7449 13.4522 44.383L12.0791 47.604L12.0791 47.604Z" fill="black"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M32.6729 41.4258C32.7184 41.9718 33.8209 44.0317 33.8611 44.5149H31.3264C31.3822 44.2755 32.4411 41.5957 32.6729 41.4258ZM28.9502 47.604H29.9799C31.2938 44.8656 29.8954 45.3862 34.0987 45.3862C34.3701 45.3862 34.5174 45.9516 34.7099 46.4385C34.8618 46.8228 35.033 47.274 35.2077 47.604H36.2373C36.1724 47.325 35.9447 46.8887 35.8193 46.5963L32.6729 39.2079C32.4983 39.336 32.5044 39.4273 32.4063 39.6542C32.3155 39.864 32.267 40.006 32.1711 40.211L29.8666 45.4314C29.6516 45.9477 29.0499 47.1758 28.9502 47.604Z" fill="black"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M51.3663 41.4258L52.6336 44.5149H50.0989C50.2012 44.0755 50.8063 42.7712 51.038 42.2065C51.1549 41.9216 51.3018 41.7023 51.3663 41.4258ZM47.7227 47.604H48.7524C48.9266 47.2409 49.0809 46.8315 49.2496 46.4378C49.8471 45.0438 49.0827 45.3862 52.8712 45.3862C53.228 45.3862 53.5929 46.8722 53.9801 47.604H55.0099L51.8635 40.2157C51.7223 39.8857 51.548 39.3412 51.3663 39.2079C51.3091 39.4533 51.2603 39.4882 51.1556 39.7101C50.3533 41.4101 49.4165 43.8351 48.6205 45.4919L47.7227 47.604Z" fill="black"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M66.2578 47.604H70.4558V46.7327H67.2083V43.7228H70.3766V42.7723H67.2083V40.4753H70.4558V39.6039H66.2578V47.604Z" fill="black"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M19.0488 40.4753H20.9499V47.604H21.9004V40.4753H23.8805V39.6039H19.0488V40.4753Z" fill="black"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M24.4355 40.4753H26.3365V47.604H27.287V40.7129C27.287 40.2996 28.1257 40.4753 29.2673 40.4753V39.6039H24.4355V40.4753Z" fill="black"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M61.9805 47.604H65.1487V46.7327H62.931V39.6039H61.9805V47.604Z" fill="black"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M37.1875 39.8416V47.604H38.138V39.6039H37.4251C37.2427 39.6039 37.1875 39.6591 37.1875 39.8416H37.1875Z" fill="black"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M119.01 40C119.01 39.2913 119.542 38.4951 120.198 38.4951H120.594C121.55 38.4951 122.245 39.9742 121.41 40.895C120.539 41.8557 119.01 41.0689 119.01 40.0001V40ZM118.614 39.7624C118.614 42.4663 122.179 42.1179 122.179 40.0792C122.179 38.8153 121.619 38.0991 120.278 38.0991C119.416 38.0991 118.614 38.9005 118.614 39.7624Z" fill="black"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M120.198 39.3663H120.515C120.506 39.7498 120.536 39.6257 120.277 39.7624C120.175 39.5495 120.207 39.6784 120.198 39.3663H120.198ZM119.564 40.6336C119.564 40.9335 119.749 40.8713 120.198 40.8713V40.396C120.358 40.513 120.456 40.6495 120.515 40.8713H121.228C121.145 40.5136 120.927 40.3403 120.753 40.0791C121.526 39.6701 121.089 38.8911 120.515 38.8911H119.564V40.6336V40.6336Z" fill="black"/>
      </g>
      <defs>
          <clipPath id="clip0_4158_467">
              <rect width="110.179" height="40" fill="white" transform="translate(12 8)"/>
          </clipPath>
      </defs>
  </svg>
)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
