import Icon from "@ant-design/icons";


const Svg = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M48.919 0.0078125H1.08104C0.495131 0.0078125 0 0.511195 0 1.08885V48.9268C0 49.5127 0.503383 50.0078 1.08104 50.0078H48.919C49.5049 50.0078 50 49.5127 50 48.9268V1.08885C49.9175 0.502943 49.4141 0.0078125 48.919 0.0078125ZM47.8379 47.8457H2.07955V2.08736H47.8379V47.8457Z" fill="white"/>
    <path d="M24.9629 42.6881C34.5271 42.6881 42.5153 35.0301 42.5978 25.3008V25.2182C42.7628 15.4807 34.7747 7.3275 24.9629 7.3275C15.151 7.3275 6.49447 16.0666 7.32794 26.4643C8.07889 35.5335 15.6461 42.6881 24.9629 42.6881ZM24.9629 40.6086C17.3956 40.6086 10.9837 35.1209 9.65506 27.7929C18.7242 30.2026 31.3666 28.6264 40.5265 26.5468C39.693 34.3699 32.9592 40.6086 24.9711 40.6086H24.9629ZM24.9629 9.40705C33.3636 9.40705 40.2707 16.0666 40.5182 24.4673C31.4491 26.5468 18.2208 28.2963 9.31672 25.6309C9.06915 16.7267 16.1413 9.40705 24.9546 9.40705H24.9629Z" fill="white"/>
    <path d="M33.2811 29.1298C32.1175 29.1298 31.2015 30.0458 31.2015 31.2093C31.2015 32.3729 32.1175 33.2889 33.2811 33.2889C34.4446 33.2889 35.3606 32.3729 35.3606 31.2093C35.3606 30.0458 34.4446 29.1298 33.2811 29.1298Z" fill="white"/>
    <path d="M27.0424 33.2889C25.8789 33.2889 24.9629 34.2049 24.9629 35.3684C24.9629 36.532 25.8789 37.448 27.0424 37.448C28.206 37.448 29.122 36.532 29.122 35.3684C29.122 34.2049 28.206 33.2889 27.0424 33.2889Z" fill="white"/>
    <path d="M19.8052 31.2093C18.6417 31.2093 17.7257 32.1253 17.7257 33.2889C17.7257 34.4524 18.6417 35.3684 19.8052 35.3684C20.9688 35.3684 21.8848 34.4524 21.8848 33.2889C21.8848 32.1253 20.8863 31.2093 19.8052 31.2093Z" fill="white"/>
  </svg>
)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
