import Icon from "@ant-design/icons";


const Svg = () => (
    <svg width="94" height="18" viewBox="0 0 94 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M7.46367 12.2104L6.00225 0.368425L0 0.947356L2.76625 17.5788H9.6558C12.683 13.5789 16.3888 7.89469 16.3888 3.5263C16.3888 2.15789 16.1278 1.05262 15.4493 0L9.96895 1.10524C10.3343 1.84208 10.5431 2.73683 10.5431 4.05261C10.5431 6.47365 8.8207 9.99994 7.46367 12.2104Z"
              fill="#008CFF"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M26.5663 0.0527344C20.3031 0.0527344 17.2236 5.57904 17.2236 10.7369C17.2236 14.7369 19.1026 17.9473 24.3219 17.9473C26.4097 17.9473 28.4974 17.4737 29.8545 16.8421L30.5852 11.8421C28.654 12.8421 27.1404 13.2105 25.6268 13.2105C24.2175 13.2105 23.1215 12.4211 23.1215 10.4737C26.3575 10.4211 31.9422 9.10532 31.9422 4.73693C31.9422 2.0001 30.0111 0.0527344 26.5663 0.0527344ZM27.1404 5.21062C27.1404 6.78956 24.3741 7.31587 23.2259 7.31587C23.3825 5.42114 24.8439 4.05273 25.94 4.05273C26.6185 4.05273 27.1404 4.42115 27.1404 5.21062Z"
              fill="#008CFF"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M35.0734 0.368541L32.3594 17.6316H38.2572L40.1362 5.52639C40.6582 5.26324 41.3888 4.89481 42.1717 4.89481C42.7459 4.89481 43.2156 5.10533 43.2156 5.84217C43.2156 6.15796 43.1112 6.73691 43.1112 7.00006L41.4411 17.579H47.2867L49.1135 6.05271C49.2179 5.42113 49.3223 4.52641 49.3223 3.89484C49.3223 1.57907 48.2784 0.0527344 45.3034 0.0527344C43.059 0.0527344 41.4932 0.89484 40.2406 1.73694L40.1884 0.368541H35.0734Z"
              fill="#008CFF"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M53.0285 0.368425L50.3145 17.6315H56.2123L58.0913 5.52628C58.6132 5.26312 59.3439 4.8947 60.1268 4.8947C60.701 4.8947 61.1707 5.10522 61.1707 5.94732C61.1707 6.15785 61.1185 6.52628 61.0663 6.78944L59.3961 17.5788H65.2418L67.1207 5.47366C67.6427 5.2105 68.3734 4.84208 69.1041 4.84208C69.6782 4.84208 70.148 5.0526 70.148 5.8947C70.148 6.10523 70.0958 6.47363 70.0436 6.73679L68.3734 17.5262H74.2191L76.0458 5.99997C76.1502 5.3684 76.2546 4.47365 76.2546 3.84207C76.2546 1.5263 75.2107 0 72.1835 0C69.9914 0 68.4256 0.684193 66.7554 1.89471C66.1291 0.789455 64.9286 0 63.1019 0C60.9619 0 59.3961 0.842093 58.1957 1.73682L58.0391 0.315774H53.0285V0.368425Z"
              fill="#008CFF"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M87.2151 0.0527344C80.6388 0.0527344 77.8203 5.15796 77.8203 10.579C77.8203 14.6842 79.4905 18 84.4489 18C91.1819 18 94.0003 12.4211 94.0003 7.05271C93.9481 3.00011 92.1213 0.0527344 87.2151 0.0527344ZM83.7182 10.8421C83.7182 8.73689 84.1879 4.57904 86.4844 4.57904C87.5283 4.57904 87.8937 5.5264 87.8937 6.94744C87.8937 9.05269 87.3717 13.4211 85.1796 13.4211C84.1879 13.4211 83.7182 12.4211 83.7182 10.8421Z"
              fill="#008CFF"/>
    </svg>
)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
