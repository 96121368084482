import React from "react";
import {Col, Grid, Image, Row} from "antd";
import {StyledPaymentSection} from "./PaymentSection.styles";
import {MyButton} from "../../../../common/MyButton/MyButton";
import {AmericanExpressIcon, MasterCardIcon, PayPalIcon, qrImage, VenmoIcon, VisaIcon} from "../../../../../assets";

const {useBreakpoint} = Grid

export const PaymentSection = () => {
  
  const screens = useBreakpoint()
  
  
  return (
    <StyledPaymentSection style={{backgroundColor: "#003399"}}>
      <Col xs={{
        pull: 1,
        span: 22,
        push: 1,
      }} lg={{
        pull: 3,
        span: 18,
        push: 3,
      }} xl={{
        pull: 4,
        span: 16,
        push: 4,
      }}>
        <Row align="middle">
          <Col xs={24} sm={24} md={5} xxl={4}>
            <Row justify="center">
              <Image preview={false} src={qrImage}/>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={{offset: 1, span: 18}} xxl={{span: 20, offset: 0}}>
            <Row justify={{xs: "center", sm: "center", md: "end"}} align="middle">
              <Col span={24} className="about">
                <Row style={{textAlign: "end"}} align="middle" className="textContainer"><span>We offer a simple, secure and convenient way to pay online for the repair service</span></Row></Col>
              <Col span={24}>
                <Row justify="end">
                  {screens.xxl ? (
                    <MyButton id="myRadioField"
                              onClick={() => window.open("https://www.paypal.com/ncp/payment/5BEZCTSSW4JMA")}>
                      <Row align="middle" justify="space-between" className="content">
                        <Col>
                          <span className="text">Pay online for your service</span>
                        </Col>
                        <Col span={14}>
                          <Row align="middle" justify="center">
                            <VenmoIcon/> <VisaIcon/> <MasterCardIcon/>
                            <AmericanExpressIcon/>
                            <PayPalIcon/>
                          </Row>
                        </Col>
                      </Row>
                    </MyButton>
                  ) : null}
                </Row>
              </Col>
            </Row>
          </Col>
          {!screens.xxl ? (
            <Col span={24}>
              <MyButton onClick={() => window.open("https://www.paypal.com/ncp/payment/5BEZCTSSW4JMA")}
                        style={{width: "100%", marginTop: 24}}
                        className="payment-btn">
                
                <Row align="middle" justify="space-between" className="content">
                  <Col xs={24} sm={24} md={24} lg={24} xl={10} className="textContainer">
                    <span className="text">Pay online for your service</span>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={14} className="iconsContainer">
                    <Row align="middle" justify="center">
                      <VenmoIcon/> <VisaIcon/> <MasterCardIcon/> <AmericanExpressIcon/>
                      <PayPalIcon/>
                    </Row>
                  </Col>
                </Row>
              
              </MyButton>
            
            </Col>
          ) : null}
        </Row>
      </Col>
    </StyledPaymentSection>
  )
}