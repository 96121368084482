import React from "react";
import {StyledSection8Container} from "./Section8.styles";
import {MyButton} from "../../../common/MyButton/MyButton";
import {useNavigate} from "react-router-dom";
import {AreaIcon} from "../../../../assets";
import {Grid} from "antd";

const {useBreakpoint} = Grid;

export const Section8 = () => {

    const screens = useBreakpoint();

    const navigate = useNavigate();



    return (
        <StyledSection8Container>

            <MyButton onClick={() => navigate("/area")} style={{position: "absolute", bottom: 40, right: !screens.sm ? 60 : !screens.lg ? 20 : 200, zIndex: 2}} type="primary"><AreaIcon /> Service Area</MyButton>
        </StyledSection8Container>
    )
}