import {ROUTE_PATH} from "../constants";
import {
    Main, Area, PrivacyPolicy, TermsAndConditions, Brands, Contacts
} from "../pages";
import {PublicLayout, RootLayout} from "../layout";

const publicRoutesArr = [
    {
        element: <PublicLayout/>,
        children: [
            {
                path: ROUTE_PATH.MAIN,
                element: <Main/>,
            },
            {
                path: ROUTE_PATH.AREA,
                element: <Area/>,
            },
            {
                path: ROUTE_PATH.BRANDS,
                element: <Brands/>,
            },
            {
                path: ROUTE_PATH.CONTACTS,
                element: <Contacts/>,
            },
            {
                path: ROUTE_PATH.PRIVACY,
                element: <PrivacyPolicy/>,
            },
            {
                path: ROUTE_PATH.TERMS,
                element: <TermsAndConditions/>,
            },

        ],
    },
]

export const ROUTES_CONFIG = [
    {
        element: <RootLayout/>,
        loader: async ({params}) => {
            // console.log('loader', {params: params})
            return null
        },
        action: async ({params, request}) => {
            // console.log('action', {params, request})
            return null
        },
        children: [
            ...publicRoutesArr,
        ]
    }
]
