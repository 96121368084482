import React from "react";
import {Col, Grid, Row} from "antd";
import {StyledContactContainer, StyledSection7Container} from "./Section7.styles";
import {
    AddressIcon,
    MailIcon, PhoneIcon,
    Section7Icon,
} from "../../../../assets";
import {MyButton} from "../../../common/MyButton/MyButton";
import {Link} from "react-router-dom";

const {useBreakpoint} = Grid


export const Section7 = ({setIsModalOpen}) => {

    const screens = useBreakpoint()

    return (
        <StyledSection7Container>
            <Col xs= {{
                pull: 1,
                span: 22,
                push: 1,
            }} lg={{
                pull: 3,
                span: 18,
                push: 3,
            }} xl={{
                pull: 4,
                span: 16,
                push: 4,
            }}>
                <Row align="middle" justify={{xs: "center", sm: "center", md: "center", lg: "start"}} className="container7">
                    <Col xs={0} md={4}><Section7Icon/></Col>
                    <Col xs={24} md={20}>
                        <Col className="section7Container">
                            {!screens.md ? (
                              <span className="section7Text">
                                Give us a call, send email request, {!screens.sm ? null : <br />} or submit the form. We can help you!
                              </span>
                            ) : (
                              <span className="section7Text">
                                Give us a call, send email request, or submit the form. {!screens.xxl ? <br/> : null} We can help you!
                              </span>
                            )}
                        </Col>
                        <Col>
                            <Row align="middle" className="buttonsContainer">
                                <Col xs={24} sm={20}>
                                
                                <StyledContactContainer align="middle">
                                    <Col xs={3} xl={2} xxl={1}>
                                        <Row justify="center">
                                            <AddressIcon/>
                                        </Row>
                                    </Col>
                                    <Col xs={21} xl={22} xxl={23}>
                                        <span><span style={{color: "#1A2D72"}}>Mailing address:</span> 3815 Mission Ave, Unit #204, Oceanside, CA 92058</span>
                                    </Col>
                                </StyledContactContainer>
                                </Col>

                                {/*<Link to="mailto:service@axisrepair.com">*/}
                                {/*    <StyledContactContainer justify={{xs: "center", xl: "space-between"}}*/}
                                {/*                            align="middle">*/}
                                {/*        <MailIcon/>*/}
                                {/*        <span>service@axisrepair.com</span>*/}
                                {/*    </StyledContactContainer>*/}
                                {/*</Link>*/}
                                {/*<Link to="tel:(760) 439-7522">*/}
                                {/*    <StyledContactContainer className="phoneContainer"*/}
                                {/*                            justify={{xs: "center", xl: "space-between"}}*/}
                                {/*                            align="middle">*/}
                                {/*        <PhoneIcon/>*/}
                                {/*        <span>(760) 439-7522</span>*/}
                                {/*    </StyledContactContainer>*/}
                                {/*</Link>*/}
                            </Row>
                        </Col>
                    </Col>
                </Row>
            </Col>
        </StyledSection7Container>
    )
}