import styled from "styled-components";
import {Button} from "antd";

export const StyledMyBackButton = styled(Button)`
  height: 48px;
  border: none;
  border-radius: 40px;
  text-transform: uppercase;
 
  background: #F5F6F7 !important;

  .text{
    margin-left: 16px;
    
    @media (max-width: 1450px){
      display: none;
    }
  }
  
  span {
    font-weight: 600; 
    letter-spacing: 2px; 
    font-size: 18px;
    line-height: 24px;
    color: rgb(29, 29, 31) !important;
    
    @media (max-width: 500px) {
      padding-left: 0 !important;
      padding-right: 0 !important;
      font-size: 14px;
      line-height: 22px;
    }
  }
  
  @media (max-width: 377px){
    height: 40px;
  }
`