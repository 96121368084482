import Icon from "@ant-design/icons";


const Svg = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16" r="16" fill="#0050E3"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M11.7625 9.64286C12.9375 8.54761 14.35 8 16 8C17.65 8 19.0625 8.54761 20.2375 9.64286C21.4125 10.7381 22 12.0595 22 13.6071C22 14.8452 21.5 16.3333 20.5 18.0714C19.5 19.8095 18.5 21.2381 17.5 22.3571L16 24C15.825 23.8333 15.6063 23.6012 15.3438 23.3036C15.0812 23.006 14.6063 22.4286 13.9188 21.5714C13.2312 20.7143 12.625 19.881 12.1 19.0714C11.575 18.2619 11.0938 17.3452 10.6562 16.3214C10.2187 15.2976 10 14.3929 10 13.6071C10 12.0595 10.5875 10.7381 11.7625 9.64286ZM14.5614 15.4286C14.9591 15.8095 15.4386 16 16 16C16.5614 16 17.0351 15.8036 17.4211 15.4107C17.807 15.0179 18 14.5476 18 14C18 13.4524 17.807 12.9821 17.4211 12.5893C17.0351 12.1964 16.5614 12 16 12C15.4386 12 14.9649 12.1964 14.5789 12.5893C14.193 12.9821 14 13.4524 14 14C14 14.5476 14.1871 15.0238 14.5614 15.4286Z"
              fill="white"/>
    </svg>
)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
