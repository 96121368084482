import Icon from "@ant-design/icons";


const Svg = () => (
    <svg width="172" height="60" viewBox="0 0 172 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.24945 28.988C15.7385 28.988 24.9289 36.7998 24.9289 46.3118C23.7341 46.5646 22.2407 46.7254 20.977 46.7254C10.9825 46.7024 2.64223 38.6838 2.27462 28.988H6.24945ZM20.977 9.25164C22.3096 9.25164 23.7571 9.43545 24.9289 9.66521C24.9289 19.1772 15.7155 27.012 6.22648 27.012H2.25164C2.94092 17.2473 11.0055 9.25164 20.977 9.25164ZM26.9048 10.2166C34.326 12.6521 39.6794 19.6827 39.7024 28C39.7254 36.1794 34.4639 43.1871 26.9048 45.7834V10.2166ZM0 49H42V7H0V49Z"
            fill="#041E50"/>
        <path
            d="M53.8281 7H65.8607V10.1074H57.3022V14.6542H65.3085V17.7616H57.3022V20.1836C57.3022 22.5598 59.0277 23.0853 60.086 23.0853H66.0678V26.1927H59.9249C55.8067 26.1927 53.8511 23.9764 53.8511 20.0693L53.8281 7Z"
            fill="#041E50"/>
        <path d="M68.4834 7H71.8194V26.2384H68.4834V7Z" fill="#041E50"/>
        <path
            d="M73.7285 19.2704C73.7285 14.7921 76.8574 11.936 81.0677 11.936C85.2779 11.936 87.9928 15.0434 87.9928 18.9505C87.9928 18.9505 87.9928 19.5674 87.9467 20.1158H77.0415C77.1565 22.3321 78.79 23.7487 81.2748 23.7487C82.9773 23.7487 83.9896 23.2003 85.0479 22.1493L87.0495 24.0685C85.2319 25.965 83.2763 26.559 81.1597 26.559C76.7654 26.559 73.7515 23.7487 73.7515 19.3846L73.7285 19.2704ZM84.5877 17.7852C84.5877 16.1401 83.0003 14.7464 81.0677 14.7464C78.9971 14.7464 77.2716 16.0944 77.1335 17.7852H84.5877Z"
            fill="#041E50"/>
        <path
            d="M96.7822 11.89C98.8529 11.89 100.785 12.5754 102.396 14.289L100.348 16.414C99.405 15.4543 98.3007 14.906 96.7822 14.906C94.3435 14.906 92.48 16.7567 92.48 19.27C92.48 21.7833 94.3435 23.6112 96.7822 23.6112C98.2777 23.6112 99.405 23.0629 100.348 22.1032L102.396 24.2281C100.785 25.9418 98.8759 26.6272 96.7822 26.6272C92.549 26.6272 89.167 23.5198 89.167 19.27C89.167 15.0202 92.549 11.89 96.7822 11.89Z"
            fill="#041E50"/>
        <path
            d="M103.96 7H107.296V12.3237H110.379V15.3397H107.296V20.1836C107.296 22.3085 108.469 23.1539 110.218 23.1539H110.379V26.2613H109.712C107.411 26.2613 103.96 25.096 103.96 20.3892V7Z"
            fill="#041E50"/>
        <path
            d="M111.967 12.3233H115.28V14.3797C115.947 12.9402 117.442 12.0263 119.444 12.0263C119.812 12.0263 120.295 12.072 120.433 12.1177V15.3165C120.042 15.2251 119.582 15.1794 119.053 15.1794C116.752 15.1794 115.28 16.6188 115.28 18.9037V26.2152H111.967V12.3233Z"
            fill="#041E50"/>
        <path
            d="M128.508 11.913C132.719 11.913 136.147 15.1118 136.147 19.2702C136.147 23.4286 132.719 26.6503 128.508 26.6503C124.298 26.6503 120.87 23.4515 120.87 19.2931C120.87 15.1346 124.252 11.913 128.508 11.913ZM128.508 23.6343C130.947 23.6343 132.834 21.7378 132.834 19.2702C132.834 16.8026 130.947 14.9061 128.508 14.9061C126.07 14.9061 124.183 16.8026 124.183 19.2702C124.183 21.7378 126.024 23.6343 128.508 23.6343Z"
            fill="#041E50"/>
        <path d="M138.057 7H141.393V26.2384H138.057V7Z" fill="#041E50"/>
        <path
            d="M143.739 12.3237H147.098V20.4349C147.098 22.6055 148.41 23.5423 150.158 23.5423C151.907 23.5423 153.218 22.6055 153.218 20.3892V12.3237H156.577V20.6177C156.577 24.639 153.632 26.5812 150.135 26.5812C146.638 26.5812 143.739 24.6847 143.739 20.7091V12.3237Z"
            fill="#041E50"/>
        <path
            d="M162.995 18.8812L158.164 12.3237H161.776L164.836 16.4821L167.873 12.3237H171.508L166.676 18.8584L171.991 26.2156H168.379L164.836 21.326L161.27 26.2384H157.681L162.995 18.8812Z"
            fill="#041E50"/>
        <path
            d="M63.238 29.1854C66.0679 29.1854 68.5756 30.2364 70.4392 32.0871L68.1385 34.1892C66.8961 32.9782 65.1706 32.3156 63.2611 32.3156C59.3729 32.3156 56.428 35.2631 56.428 39.1473C56.428 43.0315 59.3729 45.9561 63.2611 45.9561C65.1016 45.9561 66.7121 45.3392 67.9084 44.3111V40.7924H62.5248V37.7307H71.1984V45.4763C69.3809 47.6926 66.528 49.0864 63.2841 49.0864C57.5784 49.0864 53 44.8823 53 39.1473C53 33.4123 57.5323 29.2082 63.238 29.1854Z"
            fill="#041E50"/>
        <path
            d="M73.6143 34.8523H76.9272V36.9315C77.5944 35.4921 79.0899 34.5782 81.0915 34.5782C81.4596 34.5782 81.9427 34.6239 82.1038 34.6695V37.8683C81.7127 37.7769 81.2525 37.7312 80.7234 37.7312C78.4227 37.7312 76.9502 39.1707 76.9502 41.4555V48.7671H73.6373L73.6143 34.8523Z"
            fill="#041E50"/>
        <path
            d="M90.1559 34.4401C94.3891 34.4401 97.7941 37.6389 97.7941 41.7973C97.7941 45.9557 94.3661 49.1545 90.1559 49.1545C85.9456 49.1545 82.5176 45.9557 82.5176 41.7973C82.5176 37.6389 85.8996 34.4401 90.1559 34.4401ZM90.1559 46.1613C92.5946 46.1613 94.4811 44.2649 94.4811 41.7973C94.4811 39.3296 92.5946 37.4332 90.1559 37.4332C87.7171 37.4332 85.8306 39.3296 85.8306 41.7973C85.8306 44.2649 87.6711 46.1613 90.1559 46.1613Z"
            fill="#041E50"/>
        <path
            d="M99.543 34.8524H102.902V42.9636C102.902 45.1342 104.213 46.071 105.962 46.071C107.71 46.071 109.022 45.1342 109.022 42.9179V34.8296H112.381V43.1236C112.381 47.1449 109.436 49.087 105.939 49.087C102.442 49.087 99.543 47.1906 99.543 43.215V34.8524Z"
            fill="#041E50"/>
        <path
            d="M114.728 34.8522H118.064V36.8628C118.524 35.5605 120.18 34.5551 122.527 34.5551C126.461 34.5551 129.521 37.7082 129.521 41.821C129.521 45.9337 126.461 49.0868 122.527 49.0868C120.157 49.0868 118.524 48.0586 118.064 46.7791V54.1134H114.728V34.8522ZM122.09 46.0479C124.459 46.0479 126.231 44.2201 126.231 41.7981C126.231 39.3762 124.459 37.5483 122.09 37.5483C119.72 37.5483 117.81 39.3762 117.81 41.7981C117.81 44.2201 119.674 46.0479 122.09 46.0479Z"
            fill="#041E50"/>
    </svg>
)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
