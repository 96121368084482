import styled from "styled-components";

export const StyledArea = styled.div`
    margin-top: 88px;
  
  @media(max-width: 576px){
  margin-top: 72px;
  }
  .ant-form-item-control{
    .ant-input{
      border: none;

      &::placeholder{
        color: #1D1D1F;
        font-size: 16px;
      }
    }
  }
  
  div:nth-child(3){
      margin-top: 0;
    .textBlock{
      margin-top: 0;
    }
  }
`