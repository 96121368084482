import styled from 'styled-components'

export const StyledSection10Container = styled.div`
  margin-top: 40px;
  padding-bottom: 90px;
  position: relative;
  background-color: #fff;
  
  .ant-select-selector{
    border: 1px solid #CECED2!important;

    .ant-select-selection-placeholder{
      color: #1D1D1F !important;
      font-size: 16px !important;
    }
  }
    
    .ant-input{
    border: 1px solid #CECED2!important;
    }

  .textBlock{
    flex-direction: column;
    margin-top: 90px;
    margin-bottom: 60px;

    .title{
      font-size: 48px;
      line-height: 56px;
      text-align: center;
      font-weight: bold;

      @media (max-width: 470px){
        font-size: 40px;
        line-height: 40px;
      }
      
      @media (max-width: 361px){
        font-size: 32px;
        line-height: 36px;
      }
    }

    .subtitle{
      font-size: 18px;
      line-height: 28px;
      text-align: center;

      @media (max-width: 470px){
        font-size: 16px;
        line-height: 28px;
      }

        @media (max-width: 361px){
            font-size: 14px;
            line-height: 28px;
        }
    }
  }
`