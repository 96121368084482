import Icon from "@ant-design/icons";


const Svg = () => (
    <svg width="62" height="20" viewBox="0 0 62 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M21.5635 19.7123L24.6632 0.336914H29.6179L26.5185 19.7123H21.5635Z" fill="#26337A"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M44.4914 0.812922C43.5117 0.424059 41.971 0 40.0516 0C35.1529 0 31.7047 2.62934 31.676 6.39436C31.6449 9.17935 34.1367 10.7325 36.0153 11.6582C37.946 12.607 38.5947 13.2142 38.587 14.0626C38.5737 15.3603 37.046 15.9548 35.6214 15.9548C33.6377 15.9548 32.5837 15.6621 30.9542 14.9388L30.3169 14.6303L29.624 18.9673C30.7794 19.507 32.9212 19.9754 35.1441 20C40.3503 20 43.7331 17.4024 43.7727 13.3819C43.7906 11.1735 42.4703 9.49954 39.6109 8.11749C37.8794 7.21901 36.8167 6.62384 36.8289 5.71449C36.83 4.90929 37.7267 4.04716 39.6673 4.04716C41.288 4.02083 42.4612 4.39796 43.3754 4.79169L43.8204 5.01316L44.4914 0.812922Z"
                  fill="#26337A"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M51.0889 12.8423C51.4995 11.7287 53.0627 7.42656 53.0627 7.42656C53.0338 7.47778 53.471 6.30432 53.7213 5.5781L54.0557 7.24915C54.0557 7.24915 55.0064 11.8737 55.2022 12.8423C54.4309 12.8423 52.0564 12.8423 51.0889 12.8423ZM57.2013 0.356445H53.3721C52.1845 0.356445 51.2955 0.69924 50.7752 1.96255L43.417 19.7189H48.6211C48.6211 19.7189 49.4702 17.3302 49.6629 16.806C50.2305 16.806 55.2873 16.816 56.0091 16.816C56.1576 17.4922 56.6124 19.7189 56.6124 19.7189H61.2112L57.2013 0.356445Z"
                  fill="#26337A"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M17.4075 0.353516L12.5579 13.5629L12.0385 10.8777C11.1347 7.78398 8.32155 4.43072 5.1748 2.75051L9.61153 19.6957L14.8553 19.6925L22.6582 0.353516H17.4075Z"
                  fill="#26337A"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8.05588 0.339844H0.0657461L0 0.741297C6.21725 2.34625 10.3309 6.2203 12.0383 10.8772L10.302 1.97456C10.0025 0.746734 9.1319 0.382765 8.05588 0.339844Z"
                  fill="#EC982D"/>
    </svg>

)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
