import React, {createRef, useState} from "react";
import {StyledSection10Container} from "./Section10.styles";
import {Col, Flex, Form, Input, Row, Select, Grid, message} from "antd";
import InputMask from 'antd-mask-input'

import {ContactUsIcon} from "../../../../assets";
import {MyButton} from "../../../common/MyButton/MyButton";
import {axiosConfig} from "../../../../config/axios.config";
import ReCAPTCHA from "react-google-recaptcha";


const {Option} = Select;

const {useBreakpoint} = Grid;
export const Section10 = () => {
  const screens = useBreakpoint();
  const [form] = Form.useForm();

  const recaptchaRef = createRef();
  const [loading, setLoading] = useState(false)

  const services = [
    "Question",
    "Request",
    "Additional information",
    "Proposal",
    "Complaint",
    "Other"
  ]

  const onFinishForm = (values) => {
    setLoading(true)
    const recaptchaVal = recaptchaRef.current.getValue();

    form.validateFields().then(() => {
          if (recaptchaVal) {
            return axiosConfig.post("/api/axis/contact-us", {...values}).then((res) => {
              form.resetFields()
              setLoading(false)
              return message.success({content: "Submitted"})
            }).catch((res) => {
              setLoading(false)
            })
          } else {
            setLoading(false)
            message.error("Recaptcha error")
          }
        }
    )
  }

  const handleReCaptchaChange = (values) => {
    console.log("handleReCaptchaChange", values);

  }

    return (
      <StyledSection10Container>
        <Col xs={{
          pull: 1,
          span: 22,
          push: 1,
        }} lg={{
          pull: 3,
          span: 18,
          push: 3,
        }} xl={{
          pull: 4,
          span: 16,
          push: 4,
        }}>
          <Row className="textBlock" style={{flexDirection: "column", paddingTop: 80, marginBottom: 40}} align="middle">
            <h1 className="title">Contact us</h1>
            <span className="subtitle">Our customer service representatives operate 7 days a week including weekends & holidays. {!screens.lg ? null :
              <br/>} If you have any questions at all, feel free to send a message, or give us a call. </span>
          </Row>

          <Flex style={{paddingTop: 40}}>
            <Col xs={24} lg={12} xl={16}>
              <Form onFinish={onFinishForm} form={form}>
                <Row justify="space-between">
                  <Col xs={24} xl={12}>
                    <Form.Item name="name" rules={[{required: true, message: "Please enter your name"}]}>
                      <Input placeholder="Name" style={{height: 56}}/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={11}>
                    <Form.Item name="email" rules={[{
                      required: true,
                      message: "Please enter your email address in a correct format",
                      pattern: new RegExp(/^[a-zA-Z0-9^<>()[\]\\_%.#\s@"$&!]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/gm)
                    }]}>
                      <Input placeholder="Email Address" style={{height: 56}}/>
                    </Form.Item>

                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col xs={24} xl={12}>
                    <Form.Item  name="phone" rules={[{
                      required: true,
                      message: "Please enter your phone number",
                      pattern: new RegExp(/^\(\d{3}\)\d{3}-\d{4}$/)
                    }]}>
                      <InputMask mask="(000)000-0000" placeholder="Phone number" maskOptions={{lazy: true}}
                                 style={{height: 56}}/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={11}>
                    <Form.Item  name="service"
                               rules={[{required: true, message: "Please select a request type"}]}>
                      <Select placeholder="Request type" style={{height: 56}}>
                        {services.map((item) => (
                            <Option value={item} key={item}>{item}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                  <Col xs={24} xl={24}>
                    <Form.Item name="description" rules={[{required: true, message: "Please enter a description"}]}>
                      <Input.TextArea placeholder="Message"
                                      autoSize={{minRows: 3}}/>
                    </Form.Item>
                  </Col>

                <Col span={24} style={{marginBottom: 16}}>
                  <ReCAPTCHA ref={recaptchaRef} size={"normal"} sitekey="6Ld3a3wpAAAAALWuNLyQnB9G6LCXAg3C0shcMn3f"
                             onChange={handleReCaptchaChange}/>
                </Col>

                <Col span={24}>
                  <Row justify="center">
                    <MyButton loading={loading} type="primary" htmlType="submit">Send Message</MyButton>
                  </Row>
                </Col>


              </Form>
            </Col>
            <Col offset={1} xs={0} lg={4}>
              <ContactUsIcon/>
            </Col>
          </Flex>
          <p style={{color: "#fff"}}>(470)745-2788, (757)512-7277, (760)439-7522, (858)251-3936, (858)703-6126, (858)703-6127, (858)703-6132, (760)994-1652,
            (858)867-3614, (760)405-8335, (858)290-0823, (858)240-2328, (760)439-7522, (951)259-6610</p>
        </Col>
      </StyledSection10Container>
    )
}